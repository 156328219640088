/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BusinessPolicyRef } from './business-policy-ref';

/**
 * The shipping mode term and condition. <br>This optional term defines how orders created under a contract are shipped. If this term is not specified in a contract, orders can be shipped by any mode available in a store. A shipping mode is also known as a shipping provider. A shipping provider is the combination of a shipping carrier and its shipping service. For example, XYZ Courier, Overnight is a shipping provider.
 * @export
 * @interface ShippingTCShippingModeAllOf
 */
export interface ShippingTCShippingModeAllOf {
    /**
     * The full entity class name of the term and condition.
     * @type {string}
     * @memberof ShippingTCShippingModeAllOf
     */
    _class?: ShippingTCShippingModeAllOfClassEnum;
    /**
     * 
     * @type {BusinessPolicyRef}
     * @memberof ShippingTCShippingModeAllOf
     */
    shippingModePolicyRef?: BusinessPolicyRef;
}

/**
    * @export
    * @enum {string}
    */
export enum ShippingTCShippingModeAllOfClassEnum {
    ComIbmCommerceTradingEntitiesShippingTcShippingMode = 'com.ibm.commerce.trading.entities.ShippingTCShippingMode'
}



