/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerGuestIdentityHandlerGuestIdentityForm } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerGuestIdentityHandlerUserIdentity } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * GuestIdentityApi - axios parameter creator
 * @export
 */
export const GuestIdentityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates identity tokens for a guest user.
         * @summary Create identity token
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMemberHandlerGuestIdentityHandlerGuestIdentityForm} [body] Create guest identity body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guestIdentityLogin: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMemberHandlerGuestIdentityHandlerGuestIdentityForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('guestIdentityLogin', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/guestidentity`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs out a guest user.
         * @summary Log off guest user
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guestIdentityLogout: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('guestIdentityLogout', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/guestidentity/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuestIdentityApi - functional programming interface
 * @export
 */
export const GuestIdentityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuestIdentityApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates identity tokens for a guest user.
         * @summary Create identity token
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMemberHandlerGuestIdentityHandlerGuestIdentityForm} [body] Create guest identity body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guestIdentityLogin(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMemberHandlerGuestIdentityHandlerGuestIdentityForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerGuestIdentityHandlerUserIdentity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guestIdentityLogin(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logs out a guest user.
         * @summary Log off guest user
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guestIdentityLogout(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guestIdentityLogout(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuestIdentityApi - factory interface
 * @export
 */
export const GuestIdentityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuestIdentityApiFp(configuration)
    return {
        /**
         * Creates identity tokens for a guest user.
         * @summary Create identity token
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMemberHandlerGuestIdentityHandlerGuestIdentityForm} [body] Create guest identity body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guestIdentityLogin(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMemberHandlerGuestIdentityHandlerGuestIdentityForm, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerGuestIdentityHandlerUserIdentity> {
            return localVarFp.guestIdentityLogin(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Logs out a guest user.
         * @summary Log off guest user
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guestIdentityLogout(storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.guestIdentityLogout(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuestIdentityApi - object-oriented interface
 * @export
 * @class GuestIdentityApi
 * @extends {BaseAPI}
 */
export class GuestIdentityApi extends BaseAPI {
    /**
     * Creates identity tokens for a guest user.
     * @summary Create identity token
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestMemberHandlerGuestIdentityHandlerGuestIdentityForm} [body] Create guest identity body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestIdentityApi
     */
    public guestIdentityLogin(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMemberHandlerGuestIdentityHandlerGuestIdentityForm, options?: any) {
        return GuestIdentityApiFp(this.configuration).guestIdentityLogin(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logs out a guest user.
     * @summary Log off guest user
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestIdentityApi
     */
    public guestIdentityLogout(storeId: string, responseFormat?: string, options?: any) {
        return GuestIdentityApiFp(this.configuration).guestIdentityLogout(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
