/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The purchase order limited term and condition. <br>It defines user can purchase using the specified purchase order number which has the specified spending limit. Used for orders where the total amount of all orders made specifying the limited purchase order number does not exceed a spending limit. The spending limit for a limited purchase order is recorded and used to validate orders that specify a limited purchase order number to ensure the spending limit is not exceeded. Limited purchase order numbers that will be accepted when placing an order must be specified in the business account before buyers attempt to specify a limited purchase order number.
 * @export
 * @interface POTCLimitedAllOf
 */
export interface POTCLimitedAllOf {
    /**
     * The full entity class name of the term and condition.
     * @type {string}
     * @memberof POTCLimitedAllOf
     */
    _class?: POTCLimitedAllOfClassEnum;
    /**
     * The spending limit of the order.
     * @type {string}
     * @memberof POTCLimitedAllOf
     */
    spendingLimit?: string;
    /**
     * The purchase order number.
     * @type {string}
     * @memberof POTCLimitedAllOf
     */
    ponumber?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum POTCLimitedAllOfClassEnum {
    ComIbmCommerceTradingEntitiesPotcLimited = 'com.ibm.commerce.trading.entities.POTCLimited'
}



