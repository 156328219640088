/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * ClientlocationApi - axios parameter creator
 * @export
 */
export const ClientlocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientLocationId 
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationClientLocationIdDelete: async (clientLocationId: string, storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientLocationId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationClientLocationIdDelete', 'clientLocationId', clientLocationId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationClientLocationIdDelete', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocation/{clientLocationId}`
                .replace(`{${"clientLocationId"}}`, String(clientLocationId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a client locatoin by client location Id.
         * @param {string} clientLocationId 
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationClientLocationIdGet: async (clientLocationId: string, storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientLocationId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationClientLocationIdGet', 'clientLocationId', clientLocationId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationClientLocationIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocation/{clientLocationId}`
                .replace(`{${"clientLocationId"}}`, String(clientLocationId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientLocationId 
         * @param {string} storeId 
         * @param {string} [path] 
         * @param {string} [operation] 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationClientLocationIdPut: async (clientLocationId: string, storeId: string, path?: string, operation?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientLocationId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationClientLocationIdPut', 'clientLocationId', clientLocationId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationClientLocationIdPut', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocation/{clientLocationId}`
                .replace(`{${"clientLocationId"}}`, String(clientLocationId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (operation !== undefined) {
                localVarQueryParameter['operation'] = operation;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a client location
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationPost: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationPost', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocation`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by action.
         * @param {string} action 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByActionActionGet: async (action: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'action' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByActionActionGet', 'action', action)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByActionActionGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byAction/{action}`
                .replace(`{${"action"}}`, String(action))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by cell Id.
         * @param {string} cellId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByCellIdCellIdGet: async (cellId: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cellId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByCellIdCellIdGet', 'cellId', cellId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByCellIdCellIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byCellId/{cellId}`
                .replace(`{${"cellId"}}`, String(cellId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by client Id.
         * @param {string} clientId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByClientIdClientIdGet: async (clientId: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByClientIdClientIdGet', 'clientId', clientId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByClientIdClientIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byClientId/{clientId}`
                .replace(`{${"clientId"}}`, String(clientId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by client type.
         * @param {string} clientType 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByClientTypeClientTypeGet: async (clientType: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientType' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByClientTypeClientTypeGet', 'clientType', clientType)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByClientTypeClientTypeGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byClientType/{clientType}`
                .replace(`{${"clientType"}}`, String(clientType))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by device Id.
         * @param {string} deviceId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByDeviceIdDeviceIdGet: async (deviceId: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByDeviceIdDeviceIdGet', 'deviceId', deviceId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByDeviceIdDeviceIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byDeviceId/{deviceId}`
                .replace(`{${"deviceId"}}`, String(deviceId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by point of interest type.
         * @param {string} poiType 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByPOITypePoiTypeGet: async (poiType: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'poiType' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByPOITypePoiTypeGet', 'poiType', poiType)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByPOITypePoiTypeGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byPOIType/{poiType}`
                .replace(`{${"poiType"}}`, String(poiType))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by personalization Id.
         * @param {string} personalizationId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByPersonalizationIdPersonalizationIdGet: async (personalizationId: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalizationId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByPersonalizationIdPersonalizationIdGet', 'personalizationId', personalizationId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByPersonalizationIdPersonalizationIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byPersonalizationId/{personalizationId}`
                .replace(`{${"personalizationId"}}`, String(personalizationId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by point of interest Id.
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByPointOfInterestIdPointOfInterestIdGet: async (pointOfInterestId: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointOfInterestId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByPointOfInterestIdPointOfInterestIdGet', 'pointOfInterestId', pointOfInterestId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByPointOfInterestIdPointOfInterestIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byPointOfInterestId/{pointOfInterestId}`
                .replace(`{${"pointOfInterestId"}}`, String(pointOfInterestId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by region Id.
         * @param {string} regionId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByRegionIdRegionIdGet: async (regionId: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByRegionIdRegionIdGet', 'regionId', regionId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByRegionIdRegionIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byRegionId/{regionId}`
                .replace(`{${"regionId"}}`, String(regionId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by source Id.
         * @param {string} sourceId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsBySourceIdSourceIdGet: async (sourceId: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsBySourceIdSourceIdGet', 'sourceId', sourceId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsBySourceIdSourceIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/bySourceId/{sourceId}`
                .replace(`{${"sourceId"}}`, String(sourceId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by source type.
         * @param {string} sourceType 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsBySourceTypeSourceTypeGet: async (sourceType: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceType' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsBySourceTypeSourceTypeGet', 'sourceType', sourceType)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsBySourceTypeSourceTypeGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/bySourceType/{sourceType}`
                .replace(`{${"sourceType"}}`, String(sourceType))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} status 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByStatusStatusGet: async (status: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByStatusStatusGet', 'status', status)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByStatusStatusGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byStatus/{status}`
                .replace(`{${"status"}}`, String(status))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by tag.
         * @param {string} tag 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByTagTagGet: async (tag: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByTagTagGet', 'tag', tag)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByTagTagGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byTag/{tag}`
                .replace(`{${"tag"}}`, String(tag))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storeId 
         * @param {string} xPath 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByXPathGet: async (storeId: string, xPath: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByXPathGet', 'storeId', storeId)
            // verify required parameter 'xPath' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByXPathGet', 'xPath', xPath)
            const localVarPath = `/store/{storeId}/clientLocations/byXPath`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xPath !== undefined) {
                localVarQueryParameter['xPath'] = xPath;
            }

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve client locations by zone Id.
         * @param {string} zoneId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByZoneIdZoneIdGet: async (zoneId: string, storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByZoneIdZoneIdGet', 'zoneId', zoneId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsByZoneIdZoneIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations/byZoneId/{zoneId}`
                .replace(`{${"zoneId"}}`, String(zoneId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsGet: async (storeId: string, responseFormat?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdClientLocationsGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/clientLocations`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientlocationApi - functional programming interface
 * @export
 */
export const ClientlocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientlocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientLocationId 
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationClientLocationIdDelete(clientLocationId: string, storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationClientLocationIdDelete(clientLocationId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a client locatoin by client location Id.
         * @param {string} clientLocationId 
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationClientLocationIdGet(clientLocationId: string, storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationClientLocationIdGet(clientLocationId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientLocationId 
         * @param {string} storeId 
         * @param {string} [path] 
         * @param {string} [operation] 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationClientLocationIdPut(clientLocationId: string, storeId: string, path?: string, operation?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationClientLocationIdPut(clientLocationId, storeId, path, operation, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a client location
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationPost(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationPost(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by action.
         * @param {string} action 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByActionActionGet(action: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByActionActionGet(action, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by cell Id.
         * @param {string} cellId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByCellIdCellIdGet(cellId: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByCellIdCellIdGet(cellId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by client Id.
         * @param {string} clientId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByClientIdClientIdGet(clientId: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByClientIdClientIdGet(clientId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by client type.
         * @param {string} clientType 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByClientTypeClientTypeGet(clientType: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByClientTypeClientTypeGet(clientType, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by device Id.
         * @param {string} deviceId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByDeviceIdDeviceIdGet(deviceId: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByDeviceIdDeviceIdGet(deviceId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by point of interest type.
         * @param {string} poiType 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByPOITypePoiTypeGet(poiType: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByPOITypePoiTypeGet(poiType, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by personalization Id.
         * @param {string} personalizationId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByPersonalizationIdPersonalizationIdGet(personalizationId: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByPersonalizationIdPersonalizationIdGet(personalizationId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by point of interest Id.
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByPointOfInterestIdPointOfInterestIdGet(pointOfInterestId: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByPointOfInterestIdPointOfInterestIdGet(pointOfInterestId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by region Id.
         * @param {string} regionId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByRegionIdRegionIdGet(regionId: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByRegionIdRegionIdGet(regionId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by source Id.
         * @param {string} sourceId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsBySourceIdSourceIdGet(sourceId: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsBySourceIdSourceIdGet(sourceId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by source type.
         * @param {string} sourceType 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsBySourceTypeSourceTypeGet(sourceType: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsBySourceTypeSourceTypeGet(sourceType, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} status 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByStatusStatusGet(status: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByStatusStatusGet(status, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by tag.
         * @param {string} tag 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByTagTagGet(tag: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByTagTagGet(tag, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} storeId 
         * @param {string} xPath 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByXPathGet(storeId: string, xPath: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByXPathGet(storeId, xPath, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve client locations by zone Id.
         * @param {string} zoneId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsByZoneIdZoneIdGet(zoneId: string, storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsByZoneIdZoneIdGet(zoneId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdClientLocationsGet(storeId: string, responseFormat?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdClientLocationsGet(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientlocationApi - factory interface
 * @export
 */
export const ClientlocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientlocationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} clientLocationId 
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationClientLocationIdDelete(clientLocationId: string, storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationClientLocationIdDelete(clientLocationId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a client locatoin by client location Id.
         * @param {string} clientLocationId 
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationClientLocationIdGet(clientLocationId: string, storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationClientLocationIdGet(clientLocationId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientLocationId 
         * @param {string} storeId 
         * @param {string} [path] 
         * @param {string} [operation] 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationClientLocationIdPut(clientLocationId: string, storeId: string, path?: string, operation?: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationClientLocationIdPut(clientLocationId, storeId, path, operation, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a client location
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationPost(storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationPost(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by action.
         * @param {string} action 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByActionActionGet(action: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByActionActionGet(action, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by cell Id.
         * @param {string} cellId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByCellIdCellIdGet(cellId: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByCellIdCellIdGet(cellId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by client Id.
         * @param {string} clientId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByClientIdClientIdGet(clientId: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByClientIdClientIdGet(clientId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by client type.
         * @param {string} clientType 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByClientTypeClientTypeGet(clientType: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByClientTypeClientTypeGet(clientType, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by device Id.
         * @param {string} deviceId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByDeviceIdDeviceIdGet(deviceId: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByDeviceIdDeviceIdGet(deviceId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by point of interest type.
         * @param {string} poiType 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByPOITypePoiTypeGet(poiType: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByPOITypePoiTypeGet(poiType, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by personalization Id.
         * @param {string} personalizationId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByPersonalizationIdPersonalizationIdGet(personalizationId: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByPersonalizationIdPersonalizationIdGet(personalizationId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by point of interest Id.
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByPointOfInterestIdPointOfInterestIdGet(pointOfInterestId: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByPointOfInterestIdPointOfInterestIdGet(pointOfInterestId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by region Id.
         * @param {string} regionId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByRegionIdRegionIdGet(regionId: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByRegionIdRegionIdGet(regionId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by source Id.
         * @param {string} sourceId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsBySourceIdSourceIdGet(sourceId: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsBySourceIdSourceIdGet(sourceId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by source type.
         * @param {string} sourceType 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsBySourceTypeSourceTypeGet(sourceType: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsBySourceTypeSourceTypeGet(sourceType, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} status 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByStatusStatusGet(status: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByStatusStatusGet(status, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by tag.
         * @param {string} tag 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByTagTagGet(tag: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByTagTagGet(tag, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} storeId 
         * @param {string} xPath 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByXPathGet(storeId: string, xPath: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByXPathGet(storeId, xPath, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve client locations by zone Id.
         * @param {string} zoneId 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsByZoneIdZoneIdGet(zoneId: string, storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsByZoneIdZoneIdGet(zoneId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} storeId 
         * @param {Array<number>} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdClientLocationsGet(storeId: string, responseFormat?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdClientLocationsGet(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientlocationApi - object-oriented interface
 * @export
 * @class ClientlocationApi
 * @extends {BaseAPI}
 */
export class ClientlocationApi extends BaseAPI {
    /**
     * 
     * @param {string} clientLocationId 
     * @param {string} storeId 
     * @param {string} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationClientLocationIdDelete(clientLocationId: string, storeId: string, responseFormat?: string, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationClientLocationIdDelete(clientLocationId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a client locatoin by client location Id.
     * @param {string} clientLocationId 
     * @param {string} storeId 
     * @param {string} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationClientLocationIdGet(clientLocationId: string, storeId: string, responseFormat?: string, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationClientLocationIdGet(clientLocationId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientLocationId 
     * @param {string} storeId 
     * @param {string} [path] 
     * @param {string} [operation] 
     * @param {string} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationClientLocationIdPut(clientLocationId: string, storeId: string, path?: string, operation?: string, responseFormat?: string, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationClientLocationIdPut(clientLocationId, storeId, path, operation, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a client location
     * @param {string} storeId 
     * @param {string} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationPost(storeId: string, responseFormat?: string, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationPost(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by action.
     * @param {string} action 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByActionActionGet(action: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByActionActionGet(action, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by cell Id.
     * @param {string} cellId 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByCellIdCellIdGet(cellId: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByCellIdCellIdGet(cellId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by client Id.
     * @param {string} clientId 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByClientIdClientIdGet(clientId: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByClientIdClientIdGet(clientId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by client type.
     * @param {string} clientType 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByClientTypeClientTypeGet(clientType: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByClientTypeClientTypeGet(clientType, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by device Id.
     * @param {string} deviceId 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByDeviceIdDeviceIdGet(deviceId: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByDeviceIdDeviceIdGet(deviceId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by point of interest type.
     * @param {string} poiType 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByPOITypePoiTypeGet(poiType: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByPOITypePoiTypeGet(poiType, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by personalization Id.
     * @param {string} personalizationId 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByPersonalizationIdPersonalizationIdGet(personalizationId: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByPersonalizationIdPersonalizationIdGet(personalizationId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by point of interest Id.
     * @param {string} pointOfInterestId 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByPointOfInterestIdPointOfInterestIdGet(pointOfInterestId: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByPointOfInterestIdPointOfInterestIdGet(pointOfInterestId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by region Id.
     * @param {string} regionId 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByRegionIdRegionIdGet(regionId: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByRegionIdRegionIdGet(regionId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by source Id.
     * @param {string} sourceId 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsBySourceIdSourceIdGet(sourceId: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsBySourceIdSourceIdGet(sourceId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by source type.
     * @param {string} sourceType 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsBySourceTypeSourceTypeGet(sourceType: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsBySourceTypeSourceTypeGet(sourceType, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} status 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByStatusStatusGet(status: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByStatusStatusGet(status, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by tag.
     * @param {string} tag 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByTagTagGet(tag: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByTagTagGet(tag, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} storeId 
     * @param {string} xPath 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByXPathGet(storeId: string, xPath: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByXPathGet(storeId, xPath, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve client locations by zone Id.
     * @param {string} zoneId 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsByZoneIdZoneIdGet(zoneId: string, storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsByZoneIdZoneIdGet(zoneId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} storeId 
     * @param {Array<number>} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientlocationApi
     */
    public storeStoreIdClientLocationsGet(storeId: string, responseFormat?: Array<number>, options?: any) {
        return ClientlocationApiFp(this.configuration).storeStoreIdClientLocationsGet(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
