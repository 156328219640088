/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestWishlistHandlerWishlistHandlerCreateBodyParameterDescription } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestWishlistHandlerWishlistHandlerDeleteWishListResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestWishlistHandlerWishlistHandlerProcessBodyParameterDescription } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestWishlistHandlerWishlistHandlerUpdateBodyParameterDescription } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestWishlistHandlerWishlistHandlerWishListResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestWishlistHandlerWishlistHandlerWishListUpdateResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { WishlistWishlist } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * WishlistApi - axios parameter creator
 * @export
 */
export const WishlistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a wish list. The wish list can contain items, or be empty.
         * @summary Create wish list
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerCreateBodyParameterDescription} [body] The request body for creating a wish list or wish list item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistCreateWishlist: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerCreateBodyParameterDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('wishlistCreateWishlist', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/wishlist`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a wish list or delete an item from a wish list. Specify an itemId or a productId to delete only that item from the wish list; otherwise the entire wish list is deleted. When both itemId and productId are provided, itemId is used ONLY.
         * @summary Delete wish list or wish list item
         * @param {string} storeId The store identifier.
         * @param {string} externalId Wish list external identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [itemId] Specifies the ID of the wish list item to delete. If this parameter and productId are missing or blank, the entire wish list is deleted.
         * @param {string} [productId] Specifies the id of the wish list product to delete. If this parameter and itemId are missing or blank, the entire wish list is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistDeleteWishlist: async (storeId: string, externalId: string, responseFormat?: string, itemId?: string, productId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('wishlistDeleteWishlist', 'storeId', storeId)
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('wishlistDeleteWishlist', 'externalId', externalId)
            const localVarPath = `/store/{storeId}/wishlist/{externalId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"externalId"}}`, String(externalId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['itemId'] = itemId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all of the shopper\'s wish lists.
         * @summary Get all wish lists
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistFindWishlist: async (storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('wishlistFindWishlist', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/wishlist/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the shopper\'s wish list by ExternalId or by ExternalId and GuestAccesskey. If only ExternalId is specified, the user must be authenticated.  If ExternalId in not specified then it will be  redirected to /store/{storeId}/wishlist and will give userId not defined.
         * @summary Get wish list by external ID
         * @param {string} externalId Wish list external identifier.
         * @param {string} storeId The store identifier.
         * @param {string} [guestAccessKey] Wish list guest access key.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistFindWishlistByExternalId: async (externalId: string, storeId: string, guestAccessKey?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('wishlistFindWishlistByExternalId', 'externalId', externalId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('wishlistFindWishlistByExternalId', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/wishlist/{externalId}`
                .replace(`{${"externalId"}}`, String(externalId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (guestAccessKey !== undefined) {
                localVarQueryParameter['guestAccessKey'] = guestAccessKey;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the shopper\'s default wish list.
         * @summary Get default wish list
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistFindWishlistByUserIdDefault: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('wishlistFindWishlistByUserIdDefault', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/wishlist/@default`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the shopper\'s wish list items by wish list ExternalId or by ExternalId and GuestAccesskey. If only ExternalId is specified, the user must be authenticated.
         * @summary Get with list items by external ID
         * @param {string} externalId Wish list external identifier.
         * @param {string} storeId The store identifier.
         * @param {string} [guestAccessKey] Wish list guest access key.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistFindWishlistItemsByExternalId: async (externalId: string, storeId: string, guestAccessKey?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('wishlistFindWishlistItemsByExternalId', 'externalId', externalId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('wishlistFindWishlistItemsByExternalId', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/wishlist/{externalId}/item`
                .replace(`{${"externalId"}}`, String(externalId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (guestAccessKey !== undefined) {
                localVarQueryParameter['guestAccessKey'] = guestAccessKey;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the wishlists for a specific user.
         * @summary Get the wishlists for a specific user
         * @param {string} storeId The store identifier.
         * @param {string} userId The user ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The pageSize must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The pageNumber must be specified for paging to work.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistGetWishlist: async (storeId: string, userId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('wishlistGetWishlist', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('wishlistGetWishlist', 'userId', userId)
            const localVarPath = `/store/{storeId}/wishlist`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes a wish list.
         * @summary Process wish list
         * @param {string} storeId The store identifier.
         * @param {string} externalId Wish list external identifier.
         * @param {string} [action] The action of the rest service.
         * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerProcessBodyParameterDescription} [body] The request body for the e-mail announcements made about the gift list by the registrants to friends and family.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistProcessWishlist: async (storeId: string, externalId: string, action?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerProcessBodyParameterDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('wishlistProcessWishlist', 'storeId', storeId)
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('wishlistProcessWishlist', 'externalId', externalId)
            const localVarPath = `/store/{storeId}/wishlist/{externalId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"externalId"}}`, String(externalId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the wish list to change the description or to add or update an item.
         * @summary Update wish list
         * @param {string} storeId The store identifier.
         * @param {string} externalId Wish list external identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [addItem] When set to true, a new item in a request is added to the wish list. When false or missing, the wish list description and/or item is updated with the information in the request. Default is false.
         * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerUpdateBodyParameterDescription} [body] The request body for updating a wish list description or wish list item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistUpdateWishlist: async (storeId: string, externalId: string, responseFormat?: string, addItem?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerUpdateBodyParameterDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('wishlistUpdateWishlist', 'storeId', storeId)
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('wishlistUpdateWishlist', 'externalId', externalId)
            const localVarPath = `/store/{storeId}/wishlist/{externalId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"externalId"}}`, String(externalId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (addItem !== undefined) {
                localVarQueryParameter['addItem'] = addItem;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WishlistApi - functional programming interface
 * @export
 */
export const WishlistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WishlistApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a wish list. The wish list can contain items, or be empty.
         * @summary Create wish list
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerCreateBodyParameterDescription} [body] The request body for creating a wish list or wish list item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wishlistCreateWishlist(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerCreateBodyParameterDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestWishlistHandlerWishlistHandlerWishListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistCreateWishlist(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a wish list or delete an item from a wish list. Specify an itemId or a productId to delete only that item from the wish list; otherwise the entire wish list is deleted. When both itemId and productId are provided, itemId is used ONLY.
         * @summary Delete wish list or wish list item
         * @param {string} storeId The store identifier.
         * @param {string} externalId Wish list external identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [itemId] Specifies the ID of the wish list item to delete. If this parameter and productId are missing or blank, the entire wish list is deleted.
         * @param {string} [productId] Specifies the id of the wish list product to delete. If this parameter and itemId are missing or blank, the entire wish list is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wishlistDeleteWishlist(storeId: string, externalId: string, responseFormat?: string, itemId?: string, productId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestWishlistHandlerWishlistHandlerDeleteWishListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistDeleteWishlist(storeId, externalId, responseFormat, itemId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all of the shopper\'s wish lists.
         * @summary Get all wish lists
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wishlistFindWishlist(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WishlistWishlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistFindWishlist(storeId, responseFormat, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the shopper\'s wish list by ExternalId or by ExternalId and GuestAccesskey. If only ExternalId is specified, the user must be authenticated.  If ExternalId in not specified then it will be  redirected to /store/{storeId}/wishlist and will give userId not defined.
         * @summary Get wish list by external ID
         * @param {string} externalId Wish list external identifier.
         * @param {string} storeId The store identifier.
         * @param {string} [guestAccessKey] Wish list guest access key.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wishlistFindWishlistByExternalId(externalId: string, storeId: string, guestAccessKey?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WishlistWishlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistFindWishlistByExternalId(externalId, storeId, guestAccessKey, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the shopper\'s default wish list.
         * @summary Get default wish list
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wishlistFindWishlistByUserIdDefault(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WishlistWishlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistFindWishlistByUserIdDefault(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the shopper\'s wish list items by wish list ExternalId or by ExternalId and GuestAccesskey. If only ExternalId is specified, the user must be authenticated.
         * @summary Get with list items by external ID
         * @param {string} externalId Wish list external identifier.
         * @param {string} storeId The store identifier.
         * @param {string} [guestAccessKey] Wish list guest access key.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wishlistFindWishlistItemsByExternalId(externalId: string, storeId: string, guestAccessKey?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WishlistWishlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistFindWishlistItemsByExternalId(externalId, storeId, guestAccessKey, responseFormat, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the wishlists for a specific user.
         * @summary Get the wishlists for a specific user
         * @param {string} storeId The store identifier.
         * @param {string} userId The user ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The pageSize must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The pageNumber must be specified for paging to work.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wishlistGetWishlist(storeId: string, userId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WishlistWishlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistGetWishlist(storeId, userId, responseFormat, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Processes a wish list.
         * @summary Process wish list
         * @param {string} storeId The store identifier.
         * @param {string} externalId Wish list external identifier.
         * @param {string} [action] The action of the rest service.
         * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerProcessBodyParameterDescription} [body] The request body for the e-mail announcements made about the gift list by the registrants to friends and family.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wishlistProcessWishlist(storeId: string, externalId: string, action?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerProcessBodyParameterDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestWishlistHandlerWishlistHandlerWishListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistProcessWishlist(storeId, externalId, action, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the wish list to change the description or to add or update an item.
         * @summary Update wish list
         * @param {string} storeId The store identifier.
         * @param {string} externalId Wish list external identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [addItem] When set to true, a new item in a request is added to the wish list. When false or missing, the wish list description and/or item is updated with the information in the request. Default is false.
         * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerUpdateBodyParameterDescription} [body] The request body for updating a wish list description or wish list item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wishlistUpdateWishlist(storeId: string, externalId: string, responseFormat?: string, addItem?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerUpdateBodyParameterDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestWishlistHandlerWishlistHandlerWishListUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistUpdateWishlist(storeId, externalId, responseFormat, addItem, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WishlistApi - factory interface
 * @export
 */
export const WishlistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WishlistApiFp(configuration)
    return {
        /**
         * Creates a wish list. The wish list can contain items, or be empty.
         * @summary Create wish list
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerCreateBodyParameterDescription} [body] The request body for creating a wish list or wish list item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistCreateWishlist(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerCreateBodyParameterDescription, options?: any): AxiosPromise<ComIbmCommerceRestWishlistHandlerWishlistHandlerWishListResponse> {
            return localVarFp.wishlistCreateWishlist(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a wish list or delete an item from a wish list. Specify an itemId or a productId to delete only that item from the wish list; otherwise the entire wish list is deleted. When both itemId and productId are provided, itemId is used ONLY.
         * @summary Delete wish list or wish list item
         * @param {string} storeId The store identifier.
         * @param {string} externalId Wish list external identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [itemId] Specifies the ID of the wish list item to delete. If this parameter and productId are missing or blank, the entire wish list is deleted.
         * @param {string} [productId] Specifies the id of the wish list product to delete. If this parameter and itemId are missing or blank, the entire wish list is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistDeleteWishlist(storeId: string, externalId: string, responseFormat?: string, itemId?: string, productId?: string, options?: any): AxiosPromise<ComIbmCommerceRestWishlistHandlerWishlistHandlerDeleteWishListResponse> {
            return localVarFp.wishlistDeleteWishlist(storeId, externalId, responseFormat, itemId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all of the shopper\'s wish lists.
         * @summary Get all wish lists
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistFindWishlist(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<WishlistWishlist> {
            return localVarFp.wishlistFindWishlist(storeId, responseFormat, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the shopper\'s wish list by ExternalId or by ExternalId and GuestAccesskey. If only ExternalId is specified, the user must be authenticated.  If ExternalId in not specified then it will be  redirected to /store/{storeId}/wishlist and will give userId not defined.
         * @summary Get wish list by external ID
         * @param {string} externalId Wish list external identifier.
         * @param {string} storeId The store identifier.
         * @param {string} [guestAccessKey] Wish list guest access key.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistFindWishlistByExternalId(externalId: string, storeId: string, guestAccessKey?: string, responseFormat?: string, options?: any): AxiosPromise<WishlistWishlist> {
            return localVarFp.wishlistFindWishlistByExternalId(externalId, storeId, guestAccessKey, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the shopper\'s default wish list.
         * @summary Get default wish list
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistFindWishlistByUserIdDefault(storeId: string, responseFormat?: string, options?: any): AxiosPromise<WishlistWishlist> {
            return localVarFp.wishlistFindWishlistByUserIdDefault(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the shopper\'s wish list items by wish list ExternalId or by ExternalId and GuestAccesskey. If only ExternalId is specified, the user must be authenticated.
         * @summary Get with list items by external ID
         * @param {string} externalId Wish list external identifier.
         * @param {string} storeId The store identifier.
         * @param {string} [guestAccessKey] Wish list guest access key.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistFindWishlistItemsByExternalId(externalId: string, storeId: string, guestAccessKey?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<WishlistWishlist> {
            return localVarFp.wishlistFindWishlistItemsByExternalId(externalId, storeId, guestAccessKey, responseFormat, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the wishlists for a specific user.
         * @summary Get the wishlists for a specific user
         * @param {string} storeId The store identifier.
         * @param {string} userId The user ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The pageSize must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The pageNumber must be specified for paging to work.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistGetWishlist(storeId: string, userId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<WishlistWishlist> {
            return localVarFp.wishlistGetWishlist(storeId, userId, responseFormat, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes a wish list.
         * @summary Process wish list
         * @param {string} storeId The store identifier.
         * @param {string} externalId Wish list external identifier.
         * @param {string} [action] The action of the rest service.
         * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerProcessBodyParameterDescription} [body] The request body for the e-mail announcements made about the gift list by the registrants to friends and family.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistProcessWishlist(storeId: string, externalId: string, action?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerProcessBodyParameterDescription, options?: any): AxiosPromise<ComIbmCommerceRestWishlistHandlerWishlistHandlerWishListResponse> {
            return localVarFp.wishlistProcessWishlist(storeId, externalId, action, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the wish list to change the description or to add or update an item.
         * @summary Update wish list
         * @param {string} storeId The store identifier.
         * @param {string} externalId Wish list external identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [addItem] When set to true, a new item in a request is added to the wish list. When false or missing, the wish list description and/or item is updated with the information in the request. Default is false.
         * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerUpdateBodyParameterDescription} [body] The request body for updating a wish list description or wish list item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishlistUpdateWishlist(storeId: string, externalId: string, responseFormat?: string, addItem?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerUpdateBodyParameterDescription, options?: any): AxiosPromise<ComIbmCommerceRestWishlistHandlerWishlistHandlerWishListUpdateResponse> {
            return localVarFp.wishlistUpdateWishlist(storeId, externalId, responseFormat, addItem, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WishlistApi - object-oriented interface
 * @export
 * @class WishlistApi
 * @extends {BaseAPI}
 */
export class WishlistApi extends BaseAPI {
    /**
     * Creates a wish list. The wish list can contain items, or be empty.
     * @summary Create wish list
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerCreateBodyParameterDescription} [body] The request body for creating a wish list or wish list item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishlistApi
     */
    public wishlistCreateWishlist(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerCreateBodyParameterDescription, options?: any) {
        return WishlistApiFp(this.configuration).wishlistCreateWishlist(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a wish list or delete an item from a wish list. Specify an itemId or a productId to delete only that item from the wish list; otherwise the entire wish list is deleted. When both itemId and productId are provided, itemId is used ONLY.
     * @summary Delete wish list or wish list item
     * @param {string} storeId The store identifier.
     * @param {string} externalId Wish list external identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [itemId] Specifies the ID of the wish list item to delete. If this parameter and productId are missing or blank, the entire wish list is deleted.
     * @param {string} [productId] Specifies the id of the wish list product to delete. If this parameter and itemId are missing or blank, the entire wish list is deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishlistApi
     */
    public wishlistDeleteWishlist(storeId: string, externalId: string, responseFormat?: string, itemId?: string, productId?: string, options?: any) {
        return WishlistApiFp(this.configuration).wishlistDeleteWishlist(storeId, externalId, responseFormat, itemId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all of the shopper\'s wish lists.
     * @summary Get all wish lists
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishlistApi
     */
    public wishlistFindWishlist(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return WishlistApiFp(this.configuration).wishlistFindWishlist(storeId, responseFormat, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the shopper\'s wish list by ExternalId or by ExternalId and GuestAccesskey. If only ExternalId is specified, the user must be authenticated.  If ExternalId in not specified then it will be  redirected to /store/{storeId}/wishlist and will give userId not defined.
     * @summary Get wish list by external ID
     * @param {string} externalId Wish list external identifier.
     * @param {string} storeId The store identifier.
     * @param {string} [guestAccessKey] Wish list guest access key.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishlistApi
     */
    public wishlistFindWishlistByExternalId(externalId: string, storeId: string, guestAccessKey?: string, responseFormat?: string, options?: any) {
        return WishlistApiFp(this.configuration).wishlistFindWishlistByExternalId(externalId, storeId, guestAccessKey, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the shopper\'s default wish list.
     * @summary Get default wish list
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishlistApi
     */
    public wishlistFindWishlistByUserIdDefault(storeId: string, responseFormat?: string, options?: any) {
        return WishlistApiFp(this.configuration).wishlistFindWishlistByUserIdDefault(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the shopper\'s wish list items by wish list ExternalId or by ExternalId and GuestAccesskey. If only ExternalId is specified, the user must be authenticated.
     * @summary Get with list items by external ID
     * @param {string} externalId Wish list external identifier.
     * @param {string} storeId The store identifier.
     * @param {string} [guestAccessKey] Wish list guest access key.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishlistApi
     */
    public wishlistFindWishlistItemsByExternalId(externalId: string, storeId: string, guestAccessKey?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return WishlistApiFp(this.configuration).wishlistFindWishlistItemsByExternalId(externalId, storeId, guestAccessKey, responseFormat, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the wishlists for a specific user.
     * @summary Get the wishlists for a specific user
     * @param {string} storeId The store identifier.
     * @param {string} userId The user ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The pageSize must be specified for paging to work.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The pageNumber must be specified for paging to work.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishlistApi
     */
    public wishlistGetWishlist(storeId: string, userId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return WishlistApiFp(this.configuration).wishlistGetWishlist(storeId, userId, responseFormat, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes a wish list.
     * @summary Process wish list
     * @param {string} storeId The store identifier.
     * @param {string} externalId Wish list external identifier.
     * @param {string} [action] The action of the rest service.
     * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerProcessBodyParameterDescription} [body] The request body for the e-mail announcements made about the gift list by the registrants to friends and family.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishlistApi
     */
    public wishlistProcessWishlist(storeId: string, externalId: string, action?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerProcessBodyParameterDescription, options?: any) {
        return WishlistApiFp(this.configuration).wishlistProcessWishlist(storeId, externalId, action, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the wish list to change the description or to add or update an item.
     * @summary Update wish list
     * @param {string} storeId The store identifier.
     * @param {string} externalId Wish list external identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [addItem] When set to true, a new item in a request is added to the wish list. When false or missing, the wish list description and/or item is updated with the information in the request. Default is false.
     * @param {ComIbmCommerceRestWishlistHandlerWishlistHandlerUpdateBodyParameterDescription} [body] The request body for updating a wish list description or wish list item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishlistApi
     */
    public wishlistUpdateWishlist(storeId: string, externalId: string, responseFormat?: string, addItem?: string, body?: ComIbmCommerceRestWishlistHandlerWishlistHandlerUpdateBodyParameterDescription, options?: any) {
        return WishlistApiFp(this.configuration).wishlistUpdateWishlist(storeId, externalId, responseFormat, addItem, body, options).then((request) => request(this.axios, this.basePath));
    }
}
