/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceFulfillmentBeansCalculationCodeListDataBeanIBMAssociatedPromotionsListSummary } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * AssociatedPromotionApi - axios parameter creator
 * @export
 */
export const AssociatedPromotionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets promotions list by product ID.
         * @summary Get promotion list
         * @param {string} storeId The store identifier.
         * @param {string} qProductId The product ID.
         * @param {string} q The query name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [qCalculationUsageId] The calculation usage ID.
         * @param {string} [qCode] The calculation code name.
         * @param {string} [qEnableStorePath] Whether the data bean searches for calculation code based on store path. The default value is &lt;b&gt;true&lt;/b&gt;.
         * @param {string} [qIncludeCategoryPromotions] Whether to exclude category promotions. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeChildItems] Whether to include the child items. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeNonManagementCenterPromotions] Whether all the promotions in the store have been created in Management Center. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeParentCategories] Whether to retrieve the calculation codes attached to the parent category of the specified catalog group. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeParentProduct] Whether to retrieve the calculation codes attached to the parent product of the specified catalog entry. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludePromotionCode] Whether to exclude the calculation codes that require a promotion code. The default value is &lt;b&gt;true&lt;/b&gt;.
         * @param {string} [qIncludeUnentitledPromotionsByMemberGroup] Whether to include promotions that are targeted to a member group if the user does not belong to the member group. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qShipModeId] The ship mode ID.
         * @param {string} [qUserId] The user ID.
         * @param {number} [qCategoryId] 
         * @param {number} [qDisplayLevel] 
         * @param {number} [qStoreId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName: async (storeId: string, qProductId: string, q: string, responseFormat?: string, currency?: string, profileName?: string, qCalculationUsageId?: string, qCode?: string, qEnableStorePath?: string, qIncludeCategoryPromotions?: string, qIncludeChildItems?: string, qIncludeNonManagementCenterPromotions?: string, qIncludeParentCategories?: string, qIncludeParentProduct?: string, qIncludePromotionCode?: string, qIncludeUnentitledPromotionsByMemberGroup?: string, qShipModeId?: string, qUserId?: string, qCategoryId?: number, qDisplayLevel?: number, qStoreId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName', 'storeId', storeId)
            // verify required parameter 'qProductId' is not null or undefined
            assertParamExists('associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName', 'qProductId', qProductId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName', 'q', q)
            const localVarPath = `/store/{storeId}/associated_promotion`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (qProductId !== undefined) {
                localVarQueryParameter['qProductId'] = qProductId;
            }

            if (qCalculationUsageId !== undefined) {
                localVarQueryParameter['qCalculationUsageId'] = qCalculationUsageId;
            }

            if (qCode !== undefined) {
                localVarQueryParameter['qCode'] = qCode;
            }

            if (qEnableStorePath !== undefined) {
                localVarQueryParameter['qEnableStorePath'] = qEnableStorePath;
            }

            if (qIncludeCategoryPromotions !== undefined) {
                localVarQueryParameter['qIncludeCategoryPromotions'] = qIncludeCategoryPromotions;
            }

            if (qIncludeChildItems !== undefined) {
                localVarQueryParameter['qIncludeChildItems'] = qIncludeChildItems;
            }

            if (qIncludeNonManagementCenterPromotions !== undefined) {
                localVarQueryParameter['qIncludeNonManagementCenterPromotions'] = qIncludeNonManagementCenterPromotions;
            }

            if (qIncludeParentCategories !== undefined) {
                localVarQueryParameter['qIncludeParentCategories'] = qIncludeParentCategories;
            }

            if (qIncludeParentProduct !== undefined) {
                localVarQueryParameter['qIncludeParentProduct'] = qIncludeParentProduct;
            }

            if (qIncludePromotionCode !== undefined) {
                localVarQueryParameter['qIncludePromotionCode'] = qIncludePromotionCode;
            }

            if (qIncludeUnentitledPromotionsByMemberGroup !== undefined) {
                localVarQueryParameter['qIncludeUnentitledPromotionsByMemberGroup'] = qIncludeUnentitledPromotionsByMemberGroup;
            }

            if (qShipModeId !== undefined) {
                localVarQueryParameter['qShipModeId'] = qShipModeId;
            }

            if (qUserId !== undefined) {
                localVarQueryParameter['qUserId'] = qUserId;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (qCategoryId !== undefined) {
                localVarQueryParameter['qCategoryId'] = qCategoryId;
            }

            if (qDisplayLevel !== undefined) {
                localVarQueryParameter['qDisplayLevel'] = qDisplayLevel;
            }

            if (qStoreId !== undefined) {
                localVarQueryParameter['qStoreId'] = qStoreId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssociatedPromotionApi - functional programming interface
 * @export
 */
export const AssociatedPromotionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssociatedPromotionApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets promotions list by product ID.
         * @summary Get promotion list
         * @param {string} storeId The store identifier.
         * @param {string} qProductId The product ID.
         * @param {string} q The query name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [qCalculationUsageId] The calculation usage ID.
         * @param {string} [qCode] The calculation code name.
         * @param {string} [qEnableStorePath] Whether the data bean searches for calculation code based on store path. The default value is &lt;b&gt;true&lt;/b&gt;.
         * @param {string} [qIncludeCategoryPromotions] Whether to exclude category promotions. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeChildItems] Whether to include the child items. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeNonManagementCenterPromotions] Whether all the promotions in the store have been created in Management Center. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeParentCategories] Whether to retrieve the calculation codes attached to the parent category of the specified catalog group. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeParentProduct] Whether to retrieve the calculation codes attached to the parent product of the specified catalog entry. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludePromotionCode] Whether to exclude the calculation codes that require a promotion code. The default value is &lt;b&gt;true&lt;/b&gt;.
         * @param {string} [qIncludeUnentitledPromotionsByMemberGroup] Whether to include promotions that are targeted to a member group if the user does not belong to the member group. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qShipModeId] The ship mode ID.
         * @param {string} [qUserId] The user ID.
         * @param {number} [qCategoryId] 
         * @param {number} [qDisplayLevel] 
         * @param {number} [qStoreId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName(storeId: string, qProductId: string, q: string, responseFormat?: string, currency?: string, profileName?: string, qCalculationUsageId?: string, qCode?: string, qEnableStorePath?: string, qIncludeCategoryPromotions?: string, qIncludeChildItems?: string, qIncludeNonManagementCenterPromotions?: string, qIncludeParentCategories?: string, qIncludeParentProduct?: string, qIncludePromotionCode?: string, qIncludeUnentitledPromotionsByMemberGroup?: string, qShipModeId?: string, qUserId?: string, qCategoryId?: number, qDisplayLevel?: number, qStoreId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceFulfillmentBeansCalculationCodeListDataBeanIBMAssociatedPromotionsListSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName(storeId, qProductId, q, responseFormat, currency, profileName, qCalculationUsageId, qCode, qEnableStorePath, qIncludeCategoryPromotions, qIncludeChildItems, qIncludeNonManagementCenterPromotions, qIncludeParentCategories, qIncludeParentProduct, qIncludePromotionCode, qIncludeUnentitledPromotionsByMemberGroup, qShipModeId, qUserId, qCategoryId, qDisplayLevel, qStoreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssociatedPromotionApi - factory interface
 * @export
 */
export const AssociatedPromotionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssociatedPromotionApiFp(configuration)
    return {
        /**
         * Gets promotions list by product ID.
         * @summary Get promotion list
         * @param {string} storeId The store identifier.
         * @param {string} qProductId The product ID.
         * @param {string} q The query name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [qCalculationUsageId] The calculation usage ID.
         * @param {string} [qCode] The calculation code name.
         * @param {string} [qEnableStorePath] Whether the data bean searches for calculation code based on store path. The default value is &lt;b&gt;true&lt;/b&gt;.
         * @param {string} [qIncludeCategoryPromotions] Whether to exclude category promotions. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeChildItems] Whether to include the child items. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeNonManagementCenterPromotions] Whether all the promotions in the store have been created in Management Center. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeParentCategories] Whether to retrieve the calculation codes attached to the parent category of the specified catalog group. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludeParentProduct] Whether to retrieve the calculation codes attached to the parent product of the specified catalog entry. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qIncludePromotionCode] Whether to exclude the calculation codes that require a promotion code. The default value is &lt;b&gt;true&lt;/b&gt;.
         * @param {string} [qIncludeUnentitledPromotionsByMemberGroup] Whether to include promotions that are targeted to a member group if the user does not belong to the member group. The default value is &lt;b&gt;false&lt;/b&gt;.
         * @param {string} [qShipModeId] The ship mode ID.
         * @param {string} [qUserId] The user ID.
         * @param {number} [qCategoryId] 
         * @param {number} [qDisplayLevel] 
         * @param {number} [qStoreId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName(storeId: string, qProductId: string, q: string, responseFormat?: string, currency?: string, profileName?: string, qCalculationUsageId?: string, qCode?: string, qEnableStorePath?: string, qIncludeCategoryPromotions?: string, qIncludeChildItems?: string, qIncludeNonManagementCenterPromotions?: string, qIncludeParentCategories?: string, qIncludeParentProduct?: string, qIncludePromotionCode?: string, qIncludeUnentitledPromotionsByMemberGroup?: string, qShipModeId?: string, qUserId?: string, qCategoryId?: number, qDisplayLevel?: number, qStoreId?: number, options?: any): AxiosPromise<ComIbmCommerceFulfillmentBeansCalculationCodeListDataBeanIBMAssociatedPromotionsListSummary> {
            return localVarFp.associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName(storeId, qProductId, q, responseFormat, currency, profileName, qCalculationUsageId, qCode, qEnableStorePath, qIncludeCategoryPromotions, qIncludeChildItems, qIncludeNonManagementCenterPromotions, qIncludeParentCategories, qIncludeParentProduct, qIncludePromotionCode, qIncludeUnentitledPromotionsByMemberGroup, qShipModeId, qUserId, qCategoryId, qDisplayLevel, qStoreId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssociatedPromotionApi - object-oriented interface
 * @export
 * @class AssociatedPromotionApi
 * @extends {BaseAPI}
 */
export class AssociatedPromotionApi extends BaseAPI {
    /**
     * Gets promotions list by product ID.
     * @summary Get promotion list
     * @param {string} storeId The store identifier.
     * @param {string} qProductId The product ID.
     * @param {string} q The query name.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {string} [qCalculationUsageId] The calculation usage ID.
     * @param {string} [qCode] The calculation code name.
     * @param {string} [qEnableStorePath] Whether the data bean searches for calculation code based on store path. The default value is &lt;b&gt;true&lt;/b&gt;.
     * @param {string} [qIncludeCategoryPromotions] Whether to exclude category promotions. The default value is &lt;b&gt;false&lt;/b&gt;.
     * @param {string} [qIncludeChildItems] Whether to include the child items. The default value is &lt;b&gt;false&lt;/b&gt;.
     * @param {string} [qIncludeNonManagementCenterPromotions] Whether all the promotions in the store have been created in Management Center. The default value is &lt;b&gt;false&lt;/b&gt;.
     * @param {string} [qIncludeParentCategories] Whether to retrieve the calculation codes attached to the parent category of the specified catalog group. The default value is &lt;b&gt;false&lt;/b&gt;.
     * @param {string} [qIncludeParentProduct] Whether to retrieve the calculation codes attached to the parent product of the specified catalog entry. The default value is &lt;b&gt;false&lt;/b&gt;.
     * @param {string} [qIncludePromotionCode] Whether to exclude the calculation codes that require a promotion code. The default value is &lt;b&gt;true&lt;/b&gt;.
     * @param {string} [qIncludeUnentitledPromotionsByMemberGroup] Whether to include promotions that are targeted to a member group if the user does not belong to the member group. The default value is &lt;b&gt;false&lt;/b&gt;.
     * @param {string} [qShipModeId] The ship mode ID.
     * @param {string} [qUserId] The user ID.
     * @param {number} [qCategoryId] 
     * @param {number} [qDisplayLevel] 
     * @param {number} [qStoreId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssociatedPromotionApi
     */
    public associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName(storeId: string, qProductId: string, q: string, responseFormat?: string, currency?: string, profileName?: string, qCalculationUsageId?: string, qCode?: string, qEnableStorePath?: string, qIncludeCategoryPromotions?: string, qIncludeChildItems?: string, qIncludeNonManagementCenterPromotions?: string, qIncludeParentCategories?: string, qIncludeParentProduct?: string, qIncludePromotionCode?: string, qIncludeUnentitledPromotionsByMemberGroup?: string, qShipModeId?: string, qUserId?: string, qCategoryId?: number, qDisplayLevel?: number, qStoreId?: number, options?: any) {
        return AssociatedPromotionApiFp(this.configuration).associatedPromotionFindPromotionsByProductWAssociatedPromotionSummaryProfileName(storeId, qProductId, q, responseFormat, currency, profileName, qCalculationUsageId, qCode, qEnableStorePath, qIncludeCategoryPromotions, qIncludeChildItems, qIncludeNonManagementCenterPromotions, qIncludeParentCategories, qIncludeParentProduct, qIncludePromotionCode, qIncludeUnentitledPromotionsByMemberGroup, qShipModeId, qUserId, qCategoryId, qDisplayLevel, qStoreId, options).then((request) => request(this.axios, this.basePath));
    }
}
