/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import { call, delay, put, select } from "redux-saga/effects";
import { AnyAction } from "redux";
//Foundation libraries
import cartService from "../../../_foundation/apis/transaction/cart.service";
import orderService from "../../../_foundation/apis/transaction/order.service";
import shippingInfoService from "../../../_foundation/apis/transaction/shippingInfo.service";
//Custom libraries
import { ORDER_CONFIGS, MINICART_CONFIGS } from "../../../configs/order";
import { CommerceEnvironment, DISPLAY, EMPTY_STRING, RF_JSON, SUCCESS_MSG_PREFIX } from "../../../constants/common";
//Redux
import { fetchOrderItemDetailsByIds, fetchPurchasePricesByIds } from "./orderDetails";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  CheckoutActionType,
  UpdateItemPriceActionType,
  UpdateItemsShippingAddressActionType,
} from "solteq/src/types/order/order.types";
import { CHECKOUT_CART } from "constants/routes";
import { ORDER_ITEM } from "solteq/src/constants/order-item";
import { isSellerSelector } from "redux/selectors/user";
import { PurchasePriceResult } from "solteq/src/services/STRPrices.service";
import { OrderItemDetailsParams } from "solteq/src/types/order/order.types";
//Redux
import * as ACTIONS from "../../action-types/order";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";
import { orderItemsToMoveSelector } from "redux/selectors/order";
import {
  COPY_CART_SUCCESS_ACTION,
  COPY_CART_ERROR_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_ERROR_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_S_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_F_ACTION,
  FETCH_OPEN_ORDER_LINES_SUCCESS_ACTION,
  FETCH_OPEN_ORDER_LINES_ERROR_ACTION,
  GET_PAYTRAIL_PAYMETHODS_SUCCESS_ACTION,
} from "../../actions/order";
import { WISHLIST_MOVE_ITEMS_ACTION } from "../../actions/wish-list";
import { CartApi, ShippingInfoApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import { site } from "../../../_foundation/constants/site";
import { getSite } from "../../../_foundation/hooks/useSite";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { LOCALE, SELLER_PARAM } from "../../../_foundation/constants/common";
import { activeInprogressOrderSelector } from "../../selectors/order";
import { get } from "lodash-es";
import { N_ITEMS_ADDED, PARTIAL_COPY_ORDER } from "../../../constants/order";
import { UpdateItemActionType } from "./../../../solteq/src/types/order/order.types";
import { STRPricesService } from "./../../../solteq/src/services/STRPrices.service";
import axios from "axios";
import { VALIDATION_ERROR_ACTION } from "redux/actions/error";

export function* copyCart(action: AnyAction) {
  try {
    const { payload } = action;
    let beforeCopyCartQuantity: number = 0;
    let afterCopyCartQuantity: number = 0;
    try {
      const beforeCopyParameters = {
        ...payload,
        sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
      };
      const beforeCopyResponseCart = yield call(cartService.getCart, { ...beforeCopyParameters });
      beforeCopyCartQuantity =
        beforeCopyResponseCart?.data?.orderItem
          ?.flatMap((order) => parseInt(order.quantity))
          .reduce((previous, current) => previous + current, 0) ?? 0;
    } catch (e) {
      console.log("error in getting cart", e);
      beforeCopyCartQuantity = 0;
    }
    const activeInprogressOrder = yield select(activeInprogressOrderSelector);
    const { fromOrderId: fromOrderId_1, widget, orderItemCount, errorMessage } = payload;
    const params: any = {
      body: {
        fromOrderId_1,
        toOrderId: activeInprogressOrder ? activeInprogressOrder.orderId : ".**.",
        copyOrderItemId_1: "*",
      },
    };
    if (widget) {
      params["widget"] = widget;
    }
    let response = yield call(cartService.copyOrder, params);
    try {
      const afterCopyParameters = {
        ...payload,
        sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
      };

      const afterCopyResponseCart = yield call(cartService.getCart, { ...afterCopyParameters });
      afterCopyCartQuantity =
        afterCopyResponseCart?.data?.orderItem
          ?.flatMap((order) => parseInt(order.quantity))
          .reduce((previous, current) => previous + current, 0) ?? 0;
    } catch (e) {
      console.log("error in getting cart", e);
      afterCopyCartQuantity = 0;
    }
    if (afterCopyCartQuantity > (beforeCopyCartQuantity ?? 0)) {
      // also need to call calculate -- since the copy_order API doesn't
      params.body["calculationUsageId"] = ORDER_CONFIGS.calculationUsage.split(",");
      response = yield call(cartService.calculateOrder, params);

      yield put(COPY_CART_SUCCESS_ACTION({ response }));

      const b2b = getSite()?.isB2B;
      const link = !b2b ? { url: CHECKOUT_CART, textKey: `${SUCCESS_MSG_PREFIX}ViewCart` } : undefined;
      const successMessage =
        afterCopyCartQuantity === beforeCopyCartQuantity + orderItemCount
          ? {
              key: SUCCESS_MSG_PREFIX + COPY_CART_SUCCESS_ACTION.type,
              link,
            }
          : { key: SUCCESS_MSG_PREFIX + PARTIAL_COPY_ORDER, link };
      yield put(HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
    } else {
      yield put(VALIDATION_ERROR_ACTION({ errorMessage }));
    }
  } catch (e) {
    yield put(COPY_CART_ERROR_ACTION(e));
  }
}

/**
 * Saga worker to invoke add item API
 */
export function* addItem(action: any) {
  try {
    const payload = action.payload;
    const cartPayload = {
      contractId: payload.contractId,
    };
    const preSaleShipping: any[] = payload?.preSaleShipping || [];

    const _orderItems: any[] = [];
    let catentryIds: string[] = [];
    let partnumbers: string[] = [];
    let quantities: any[] = [];
    let physicalStoreIds: string[] = [];
    let shipModeIds: string[] = [];
    if (payload.partnumber) {
      partnumbers = payload.partnumber instanceof Array ? payload.partnumber : [payload.partnumber];
      quantities = payload.quantity instanceof Array ? payload.quantity : [payload.quantity];
    } else if (payload.catentryId) {
      catentryIds = payload.catentryId instanceof Array ? payload.catentryId : [payload.catentryId];
      quantities = payload.quantity instanceof Array ? payload.quantity : [payload.quantity];
    }
    if (payload.physicalStoreId) {
      physicalStoreIds = payload.physicalStoreId instanceof Array ? payload.physicalStoreId : [payload.physicalStoreId];
    }
    if (payload.shipModeId) {
      shipModeIds = payload.shipModeId instanceof Array ? payload.shipModeId : [payload.shipModeId];
    }
    const requestedShipDates: any[] = [payload?.requestedShipDate];
    const shippingAddressId: string = payload?.shippingAddressId;

    for (const i in partnumbers) {
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        partNumber: partnumbers[i],
        contractId: payload.contractId,
        requestedShipDate: requestedShipDates[i],
      };
      _orderItems[i] = addPreSaleShippingAttributeIfNeeded(_orderItems[i], preSaleShipping[i]);

      if (physicalStoreIds[i]) {
        Object.assign(_orderItems[i], { physicalStoreId: physicalStoreIds[i] });
      }
      if (shipModeIds[i]) {
        Object.assign(_orderItems[i], { shipModeId: shipModeIds[i] });
      }
    }
    for (const i in catentryIds) {
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        productId: catentryIds[i],
        contractId: payload.contractId,
        requestedShipDate: requestedShipDates[i],
      };
      _orderItems[i] = addPreSaleShippingAttributeIfNeeded(_orderItems[i], preSaleShipping[i]);

      if (physicalStoreIds[i]) {
        Object.assign(_orderItems[i], { physicalStoreId: physicalStoreIds[i] });
      }
      if (shipModeIds[i]) {
        Object.assign(_orderItems[i], { shipModeId: shipModeIds[i] });
      }
    }
    let body = {};
    if (payload.widget) {
      body["widget"] = payload.widget;
      cartPayload["widget"] = payload.widget;
    }

    let response: any = {};
    body = {
      body: {
        orderId: ".",
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderItems,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation === "true" && !payload.preSale ? "true" : "false",
      },
    };
    response = yield call(cartService.addOrderItem, body);

    // Have to set the shipping address with a separate call this way.
    if (shippingAddressId && response.data) {
      let foo: UpdateItemsShippingAddressActionType = {
        ...payload,
        body: {
          orderItem: response.data.orderItem.map((i) => {
            return {
              orderItemId: i.orderItemId,
              addressId: shippingAddressId,
            };
          }),
          orderId: response.data.orderId,
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          x_calculationUsage: ORDER_CONFIGS.calculationUsage,
          x_check: ORDER_CONFIGS.check,
          x_remerge: ORDER_CONFIGS.remerge,
          x_backorder: ORDER_CONFIGS.backOrder,
        },
      };

      yield call(updateItemsShippingAddress, { ...action, payload: foo });
    }

    yield put({
      type: ACTIONS.ITEM_ADD_SUCCESS,
      response: response.data,
      payload: payload,
    });

    const cartAction = { ...action, payload: cartPayload };
    yield call(fetchCart, cartAction);

    if (!payload.suppressMessage) {
      const b2b = getSite()?.isB2B;
      const link =
        !b2b && partnumbers.length === 1 ? { url: CHECKOUT_CART, textKey: `${SUCCESS_MSG_PREFIX}ViewCart` } : undefined;
      const msg = {
        key: SUCCESS_MSG_PREFIX + (partnumbers.length > 1 ? N_ITEMS_ADDED : ACTIONS.ITEM_ADD_SUCCESS),
        messageParameters: partnumbers.length > 1 ? { v: String(partnumbers.length) } : undefined,
        link,
      };
      yield put(HANDLE_SUCCESS_MESSAGE_ACTION(msg));
      if (payload.fromWishList) {
        yield put(WISHLIST_MOVE_ITEMS_ACTION(payload));
      }
    }
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_ADD_ERROR, error });
  }
}

/**
 * Saga worker to invoke remove item API
 */
export function* removeItem(action: any) {
  try {
    const payload = action.payload;
    const { widget, orderItemId } = payload;
    const activeInprogressOrder = yield select(activeInprogressOrderSelector);
    const orderId = activeInprogressOrder ? activeInprogressOrder.orderId : undefined;
    const body = {
      orderId,
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      orderItemId,
      x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
      orderItem: [
        {
          quantity: "0",
          orderItemId: orderItemId,
        },
      ],
    };

    const response = yield call(cartService.updateOrderItem, { body, widget });

    yield put({ type: ACTIONS.ITEM_REMOVE_SUCCESS, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_REMOVE_ERROR, error });
  }
}

/**
 * Saga worker to remove multiple order items
 * @param action
 */
export function* removeItems(action: any) {
  try {
    const payload = action.payload;
    const orderItemIds: string[] = payload.orderItemIds;

    const body = {
      body: {
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderItem: orderItemIds.map((o) => ({
          quantity: "0",
          orderItemId: o,
        })),
      },
    };
    if (payload.widget) {
      body["widget"] = payload.widget;
    }

    const response = yield call(cartService.updateOrderItem, body);
    yield put({ type: ACTIONS.ITEMS_REMOVE_SUCCESS, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.ITEMS_REMOVE_ERROR, error });
  }
}

export function* updateShippingAddress(action: any) {
  try {
    const addressId = action.payload.addressId;
    const items = yield select(orderItemsToMoveSelector);

    const body = {
      ...action.payload.shipInfo,
      orderItem: items.map((x) => {
        return {
          orderItemId: x,
          addressId: addressId,
        };
      }),
    };

    const payload = {
      ...action.payload.payloadBase,
      body,
    };

    yield call(shippingInfoService.updateOrderShippingInfo, payload);
  } catch (error) {
    yield put({ type: ACTIONS.ITEMS_COPY_ERROR, error });
  }
}

/**
 * Saga worker to invoke update item API
 */
export function* updateItem(action: PayloadAction<UpdateItemActionType>) {
  try {
    const payload = action.payload;
    const { orderItemIds, quantity, widget, requestedShipDate, preSaleShipping } = payload;

    const orderItems = orderItemIds.map((id) => {
      const orderItem = {
        quantity: quantity,
        orderItemId: id,
        requestedShipDate: requestedShipDate,
      };
      return addPreSaleShippingAttributeIfNeeded(orderItem, preSaleShipping);
    });

    const activeInprogressOrder = yield select(activeInprogressOrderSelector);
    const orderId = activeInprogressOrder ? activeInprogressOrder.orderId : undefined;
    const body = {
      orderId,
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
      orderItem: orderItems,
    };

    const response = yield call(cartService.updateOrderItem, { widget, body });

    yield put({ type: ACTIONS.ITEM_UPDATE_SUCCESS, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_UPDATE_ERROR, error });
  }
}

function addPreSaleShippingAttributeIfNeeded(orderItem, preSaleShippingValue) {
  if (preSaleShippingValue) {
    orderItem = {
      ...orderItem,
      orderItemExtendAttribute: [
        {
          attributeName: ORDER_ITEM.EXT_ATTRIBUTE.PRE_SALE_SHIPPING.NAME,
          attributeValue: preSaleShippingValue,
          attributeType: "STRING",
        },
      ],
    };
  }
  return orderItem;
}

export function* initFromStorageFetchCart(action: any) {
  const { WCToken, rememberMe } = action.payload || {};
  if (WCToken || rememberMe) {
    yield* fetchCart(action);
  }
}

/**
 * Saga worker to invoke get cart API
 */
export function* fetchCart(action: any) {
  const activeInprogressOrder = yield select(activeInprogressOrderSelector);
  try {
    const payload = action.payload;
    const parameters = {
      ...payload,
      sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
    };

    const fetchCatentries: boolean = payload.fetchCatentries ? payload.fetchCatentries : false;
    const checkInventory: boolean = payload.checkInventory ? payload.checkInventory : false;

    if (payload.widget) {
      parameters["widget"] = payload.widget;
    }

    if (!activeInprogressOrder) {
      const responseCart = yield call(cartService.getCart, { ...parameters });
      yield* fetchShipInfo(action);

      let catentries: any = null;
      const purchasePrices: Map<string, number> = new Map();
      if (responseCart) {
        const cart = responseCart.data;

        if (cart) {
          const orderItems = cart.orderItem;

          if (orderItems && orderItems.length > 0) {
            let catentryIdList: string[] = [];
            const contracts = orderItems.reduce((p, c) => {
              if (p[c.contractId]) {
                if (p[c.contractId].indexOf(c.productId) === -1) {
                  p[c.contractId].push(c.productId);
                }
              } else {
                p[c.contractId] = [c.productId];
              }
              return p;
            }, {});

            if (fetchCatentries || orderItems.length <= MINICART_CONFIGS.maxItemsToShow) {
              //get product info for all items
              orderItems.forEach((item: any, index: number) => {
                catentryIdList.push(item.productId);
              });
            } else {
              //get product info for mini cart only
              orderItems.slice(MINICART_CONFIGS.maxItemsToShow * -1).forEach((item: any, index: number) => {
                catentryIdList.push(item.productId);
              });
            }

            if (catentryIdList.length > 0) {
              catentryIdList = [...new Set(catentryIdList)];

              const currency = parameters ? parameters.currency : "";
              const contractId = parameters ? parameters.contractId : "";
              const paramsProduct: OrderItemDetailsParams = {
                currency: currency,
                contractId: contractId,
                id: catentryIdList,
                contracts: contracts,
                // add empty seller parameter -- don't filter when fetching order-item details
                query: { [SELLER_PARAM]: EMPTY_STRING },
              };
              if (parameters?.cancelToken) {
                paramsProduct.cancelToken = parameters.cancelToken;
              }
              if (parameters?.widget) {
                paramsProduct.widget = parameters.widget;
              }

              try {
                const contents = yield call(fetchOrderItemDetailsByIds, paramsProduct);

                if (contents) {
                  catentries = {};
                  contents.forEach((catentry: any, index: number) => {
                    const listPrice = catentry.price?.find((p) => p.usage === DISPLAY);
                    const { seller, sellerId } = catentry;
                    const obj = {
                      name: catentry.name,
                      thumbnail: catentry.thumbnail,
                      attributes: catentry.attributes,
                      seo: catentry.seo,
                      listPrice: listPrice?.value ?? undefined,
                      disallowRecurringOrder: catentry.disallowRecurringOrder,
                      parentCatalogGroupID: catentry.parentCatalogGroupID,
                      seller,
                      sellerId,
                    };
                    catentries[catentry.id] = obj;
                  });
                }

                const userIsSeller: boolean = yield select(isSellerSelector);
                if (userIsSeller) {
                  const prices: PurchasePriceResult[] = yield call(fetchPurchasePricesByIds, catentryIdList);

                  prices.forEach((p) => {
                    if (
                      !purchasePrices.has(p.catalogEntryId) &&
                      p.unitPrice &&
                      p.unitPrice.length > 0 &&
                      p.unitPrice[0].price &&
                      p.unitPrice[0].price.value
                    ) {
                      purchasePrices.set(p.catalogEntryId, p.unitPrice[0].price.value);
                    }
                  });
                }
              } catch (error) {
                console.log("Could not retrieve products");
                //Cannot retrieve catentry details; return order items as-is
                catentries = null;
              }
            }
          }
        }
      }
      if (catentries != null) {
        yield put({
          type: ACTIONS.CART_GET_SUCCESS,
          response: responseCart.data,
          catentries: catentries,
          purchasePrices: purchasePrices,
          checkInventory: checkInventory,
        });
      } else {
        yield put({
          type: ACTIONS.CART_GET_SUCCESS,
          response: responseCart.data,
          checkInventory: checkInventory,
        });
      }
    } else {
      const siteInfo = getSite();
      const oderPayload = {
        contractId: payload.contractId,
        orderId: activeInprogressOrder.orderId,
        skipErrorSnackbar: true,
        currency: siteInfo?.defaultCurrencyID ?? "",
      };
      yield put(FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ACTION(oderPayload));
    }
  } catch (error) {
    yield put({
      type: ACTIONS.CART_GET_ERROR,
      error,
    });
  }
}
const fetchShipInfoActionQueue: Array<any> = [];

/**
 * Saga worker to invoke get usable ship info API
 */
export function* fetchShipInfo(action: any) {
  fetchShipInfoActionQueue.push(action.payload);
  if (fetchShipInfoActionQueue.length === 1) {
    yield* fetchShipInfoQueue();
  }
}

function* fetchShipInfoQueue() {
  while (fetchShipInfoActionQueue.length > 0) {
    const payload = fetchShipInfoActionQueue.shift();
    try {
      const response = yield call(cartService.getUsableShippingInfo, payload);
      yield put({
        type: ACTIONS.SHIPINFO_GET_SUCCESS,
        response: response.data,
      });
    } catch (error) {
      yield put({ type: ACTIONS.SHIPINFO_GET_ERROR, error });
    }
    // Add a delay between calls
    yield delay(500);
  }
}

/**
 * Saga worker to invoke get usable ship modes API
 */
export function* fetchShipModes(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(cartService.getUsableShippingMode, payload);
    yield put({
      type: ACTIONS.SHIPMODES_GET_SUCCESS,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: ACTIONS.SHIPMODES_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke update ship mode API
 */
export function* updateShipMode(action: any) {
  const payload = action.payload;
  const body = {
    body: {
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      x_allocate: ORDER_CONFIGS.allocate,
      x_backorder: ORDER_CONFIGS.backOrder,
      x_remerge: ORDER_CONFIGS.remerge,
      x_check: ORDER_CONFIGS.check,
      orderId: ".",
      shipModeId: payload.shipModeId,
      //addressId: payload.shipAddressId,
      orderItem: [], //bypass defect HC-2784
    },
  };
  if (payload.widget) {
    body["widget"] = payload.widget;
  }
  const response = yield call(shippingInfoService.updateOrderShippingInfo, body);
  yield put({
    type: ACTIONS.SHIPMODE_UPDATE_SUCCESS,
    response: response.data,
  });
}

/**
 * Saga worker to invoke get usable payment methods API
 */
export function* fetchPayMethods(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(cartService.getUsablePaymentInfo, payload);
    yield put({
      type: ACTIONS.PAYMETHODS_GET_SUCCESS,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PAYMETHODS_GET_ERROR, error });
  }
}

export function* checkoutOrder(action: PayloadAction<CheckoutActionType>) {
  const payload = action.payload;

  let param = {
    ...payload.payloadBase,
    body: {
      notifyOrderSubmitted: "1",
      notifyMerchant: "1",
      notifyShopper: "1",
      ...payload.payloadBodyBase,
    },
  };

  try {
    yield call(cartService.checkOut, param);
    yield call(payload.successCallback);
    yield put({ type: ACTIONS.TOGGLE_ORDER_BAN });
  } catch (error) {
    console.log(error);
  }
}

export function* updateItemsShippingAddress(action: PayloadAction<UpdateItemsShippingAddressActionType>) {
  const payload = action.payload;

  try {
    yield call(shippingInfoService.updateOrderShippingInfo, payload);
  } catch (error) {
    console.log(error);
  }
}

export function* updateItemsPrice(action: PayloadAction<UpdateItemPriceActionType>) {
  const payload = action.payload;

  try {
    const response = yield call(STRPricesService.updateItemsPrices, payload);

    yield put({ type: ACTIONS.UPDATE_ITEMS_PRICE_SUCCESS, response });
  } catch (error) {
    yield put({ type: ACTIONS.UPDATE_ITEMS_PRICE_ERROR, error });
  }
}

export function* getAllOrders(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(orderService.findByStatus, payload);
    yield put({
      type: ACTIONS.FETCH_ALL_ORDERS_SUCCESS,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: ACTIONS.FETCH_ALL_ORDERS_ERROR, error });
  }
}

export function* getAllowableShipmodes(action: any) {
  const shipApi = new ShippingInfoApi(undefined, site.transactionContext);
  const siteInfo = getSite();
  const storeId = siteInfo?.storeID ?? "";
  const locale = localStorageUtil.get(LOCALE);
  const lang = CommerceEnvironment.reverseLanguageMap[locale];

  try {
    const r = yield call(shipApi.cartGetAllowableShippingModes.bind(shipApi), storeId, locale, lang, RF_JSON);
    const p = { ...action.payload, modes: r.data.usableShippingMode };
    yield put(FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION(p));
  } catch (e) {
    yield put(FETCH_ALLOWABLE_SHIPMODES_ERROR_ACTION(e));
  }
}

export function* fetchActiveInprogressOrderItem(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(orderService.findByOrderId, payload);
    const orderDetails = response.data;

    const fetchCatentries: boolean = payload.fetchCatentries ? payload.fetchCatentries : false;

    const checkInventory: boolean = payload.checkInventory ? payload.checkInventory : false;

    let catentries: any = null;
    if (orderDetails) {
      const orderItems = orderDetails.orderItem;
      if (orderItems && orderItems.length > 0) {
        let catentryIdList: string[] = [];
        const contracts = orderItems.reduce((p, c) => {
          if (p[c.contractId]) {
            if (p[c.contractId].indexOf(c.productId) === -1) {
              p[c.contractId].push(c.productId);
            }
          } else {
            p[c.contractId] = [c.productId];
          }
          return p;
        }, {});

        if (fetchCatentries || orderItems.length <= MINICART_CONFIGS.maxItemsToShow) {
          //get product info for all items
          orderItems.forEach((item: any, index: number) => {
            catentryIdList.push(item.productId);
          });
        } else {
          //get product info for mini cart only
          orderItems.slice(MINICART_CONFIGS.maxItemsToShow * -1).forEach((item: any, index: number) => {
            catentryIdList.push(item.productId);
          });
        }

        if (catentryIdList.length > 0) {
          catentryIdList = [...new Set(catentryIdList)];

          const currency = payload ? payload.currency : "";
          const paramsProduct: any = {
            currency: currency,
            id: catentryIdList,
            contracts: contracts,
            // add empty seller parameter -- don't filter when fetching order-item details
            query: { [SELLER_PARAM]: EMPTY_STRING },
          };
          if (payload?.cancelToken) {
            paramsProduct["cancelToken"] = payload.cancelToken;
          }
          if (payload?.widget) {
            paramsProduct["widget"] = payload.widget;
          }

          try {
            const contents = yield call(fetchOrderItemDetailsByIds, paramsProduct);

            if (contents) {
              catentries = {};
              contents.forEach((catentry: any, index: number) => {
                const { seller, sellerId } = catentry;
                const obj = {
                  name: catentry.name,
                  thumbnail: catentry.thumbnail,
                  attributes: catentry.attributes,
                  seo: catentry.seo,
                  disallowRecurringOrder: catentry.disallowRecurringOrder,
                  parentCatalogGroupID: catentry.parentCatalogGroupID,
                  seller,
                  sellerId,
                };
                catentries[catentry.id] = obj;
              });
            }
          } catch (error) {
            console.log("Could not retrieve products");
            //Cannot retrieve catentry details; return order items as-is
            catentries = null;
          }
        }
      }
    }

    yield put(
      FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS_ACTION({
        orderDetails: orderDetails,
        catentries: catentries,
        checkInventory: checkInventory,
      })
    );
  } catch (error) {
    yield put(FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR_ACTION({ error }));
  }
}

export function* getAllowablePaymethods(action: any) {
  const api = new CartApi(undefined, site.transactionContext);
  const siteInfo = getSite();
  const storeId = siteInfo?.storeID ?? "";

  try {
    const r = yield call(api.cartGetPaymentPolicyListDataBean.bind(api), storeId, RF_JSON);

    const p = {
      ...action.payload,
      methods: get(r, "data.resultList[0].paymentPolicyInfoUsableWithoutTA", []),
    };
    yield put(FETCH_ALLOWABLE_PAYMETHODS_S_ACTION(p));
  } catch (e) {
    yield put(FETCH_ALLOWABLE_PAYMETHODS_F_ACTION(e));
  }
}

export function* getOpenOrderLines(action: PayloadAction<any>) {
  const siteInfo = getSite();
  const storeId = siteInfo?.storeID ?? "";

  try {
    const res = yield axios.get(`${site.transactionContext}/store/${storeId}/order/openOrderLines`);

    yield put(
      FETCH_OPEN_ORDER_LINES_SUCCESS_ACTION({
        ...action.payload,
        orderLines: res.data?.openOrderLines?.OrderLine ?? [],
      })
    );
  } catch (e) {
    FETCH_OPEN_ORDER_LINES_ERROR_ACTION({ ...action.payload, orderLines: [] });
  }
}

export function* getPaytrailPaymentMethods(action: PayloadAction<any>) {
  const payload = action.payload;
  const siteInfo = getSite();
  const storeId = siteInfo?.storeID ?? "";

  try {
    const response = yield axios.get(
      `${site.transactionContext}/store/${storeId}/xcart/${payload.orderId}/paytrail/paymentMethods`
    );

    yield put(
      GET_PAYTRAIL_PAYMETHODS_SUCCESS_ACTION({
        ...payload,
        methods: response.data?.paymentMethods,
      })
    );
  } catch (e) {
    yield put({ type: ACTIONS.PAYMETHODS_GET_ERROR, e });
  }
}
