/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestProduct } from './com-ibm-commerce-rest-price-handler-price-handler-price-request-product';

/**
 * Query information.
 * @export
 * @interface ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestQuery
 */
export interface ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestQuery {
    /**
     * The dates on which to query prices on all the products unless overridden at the product level.
     * @type {Array<string>}
     * @memberof ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestQuery
     */
    dates?: Array<string>;
    /**
     * The products to query.
     * @type {Array<ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestProduct>}
     * @memberof ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestQuery
     */
    products: Array<ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestProduct>;
    /**
     * The price query name. Valid names are \'byProductID\' which requires product IDs to be given for each product and \'byPartNumber\' which requires part numbers to be given for each product.
     * @type {string}
     * @memberof ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestQuery
     */
    name: ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestQueryNameEnum;
    /**
     * The contract identifiers to use for all the products unless overridden at the product level. An exception will be thrown if an invalid contract identifier is specified. If left empty, the store default contract will be used.
     * @type {Array<string>}
     * @memberof ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestQuery
     */
    contractIds?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequestQueryNameEnum {
    ByProductId = 'byProductID',
    ByPartNumber = 'byPartNumber'
}



