import { useEffect, useMemo } from "react";
import { PayloadBaseType } from "../types/common.types";

/** An extendable payload base for axios calls
 * @param widget widget name (required)
 * @param props optional payload params
 */
export const usePayloadBase = (widget?: string, props?: Record<string, unknown>) => {
  const controller = useMemo(() => new AbortController(), []);

  const payloadBase: PayloadBaseType = useMemo(() => {
    return {
      widget,
      ...props,
      signal: controller.signal,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget]);

  useEffect(() => {
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    payloadBase,
  };
};
