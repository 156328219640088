/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * HealthCheckPingApi - axios parameter creator
 * @export
 */
export const HealthCheckPingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checks the health status of ts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthPingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckPingApi - functional programming interface
 * @export
 */
export const HealthCheckPingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCheckPingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Checks the health status of ts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthPingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthPingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthCheckPingApi - factory interface
 * @export
 */
export const HealthCheckPingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCheckPingApiFp(configuration)
    return {
        /**
         * 
         * @summary Checks the health status of ts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthPingGet(options?: any): AxiosPromise<void> {
            return localVarFp.healthPingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCheckPingApi - object-oriented interface
 * @export
 * @class HealthCheckPingApi
 * @extends {BaseAPI}
 */
export class HealthCheckPingApi extends BaseAPI {
    /**
     * 
     * @summary Checks the health status of ts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckPingApi
     */
    public healthPingGet(options?: any) {
        return HealthCheckPingApiFp(this.configuration).healthPingGet(options).then((request) => request(this.axios, this.basePath));
    }
}
