/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { CatalogEntryIBMAdminCatalogEntryDefiningAttributeDictionaryAttribute } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { CatalogEntryIBMAdminStandardOfferPrice } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CatalogEntryApi - axios parameter creator
 * @export
 */
export const CatalogEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Finds catalog entries by their parent part number. Invalid part number is ignored.
         * @summary Get catalog entries by parent part
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {Array<string>} partNumber The part number of the parent catalog entry.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [catalogId] The unique id of the catalog to search the catalog entries under. If no catalog specified, store default catalog is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogEntryByParentPartNumbers: async (storeId: string, q: string, partNumber: Array<string>, langId?: string, catalogId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('catalogEntryByParentPartNumbers', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('catalogEntryByParentPartNumbers', 'q', q)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('catalogEntryByParentPartNumbers', 'partNumber', partNumber)
            const localVarPath = `/store/{storeId}/catalog_entry`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (partNumber) {
                localVarQueryParameter['partNumber'] = partNumber.join(COLLECTION_FORMATS.csv);
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets catalog entries by search term.
         * @summary Get catalog entries by search term
         * @param {string} storeId The store identifier.
         * @param {string} searchTerm The term user is searching for.
         * @param {Array<'Product' | 'Item' | 'CategoryItem' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>} [type] The catalog entry types to return.
         * @param {Array<'Product' | 'Item' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>} [excludeType] The catalog entry types that are not returned.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [catalogId] The unique id of the catalog to search the catalog entries under. If no catalog specified, store default catalog is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogEntryFindBySearchTerm: async (storeId: string, searchTerm: string, type?: Array<'Product' | 'Item' | 'CategoryItem' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>, excludeType?: Array<'Product' | 'Item' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>, langId?: string, catalogId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('catalogEntryFindBySearchTerm', 'storeId', storeId)
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('catalogEntryFindBySearchTerm', 'searchTerm', searchTerm)
            const localVarPath = `/store/{storeId}/catalog_entry/bySearchTerm/{searchTerm}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"searchTerm"}}`, String(searchTerm));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type) {
                localVarQueryParameter['type'] = type.join(COLLECTION_FORMATS.csv);
            }

            if (excludeType) {
                localVarQueryParameter['excludeType'] = excludeType.join(COLLECTION_FORMATS.csv);
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogEntryApi - functional programming interface
 * @export
 */
export const CatalogEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * Finds catalog entries by their parent part number. Invalid part number is ignored.
         * @summary Get catalog entries by parent part
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {Array<string>} partNumber The part number of the parent catalog entry.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [catalogId] The unique id of the catalog to search the catalog entries under. If no catalog specified, store default catalog is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogEntryByParentPartNumbers(storeId: string, q: string, partNumber: Array<string>, langId?: string, catalogId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogEntryIBMAdminCatalogEntryDefiningAttributeDictionaryAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogEntryByParentPartNumbers(storeId, q, partNumber, langId, catalogId, profileName, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets catalog entries by search term.
         * @summary Get catalog entries by search term
         * @param {string} storeId The store identifier.
         * @param {string} searchTerm The term user is searching for.
         * @param {Array<'Product' | 'Item' | 'CategoryItem' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>} [type] The catalog entry types to return.
         * @param {Array<'Product' | 'Item' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>} [excludeType] The catalog entry types that are not returned.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [catalogId] The unique id of the catalog to search the catalog entries under. If no catalog specified, store default catalog is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogEntryFindBySearchTerm(storeId: string, searchTerm: string, type?: Array<'Product' | 'Item' | 'CategoryItem' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>, excludeType?: Array<'Product' | 'Item' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>, langId?: string, catalogId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogEntryIBMAdminStandardOfferPrice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogEntryFindBySearchTerm(storeId, searchTerm, type, excludeType, langId, catalogId, profileName, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CatalogEntryApi - factory interface
 * @export
 */
export const CatalogEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogEntryApiFp(configuration)
    return {
        /**
         * Finds catalog entries by their parent part number. Invalid part number is ignored.
         * @summary Get catalog entries by parent part
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {Array<string>} partNumber The part number of the parent catalog entry.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [catalogId] The unique id of the catalog to search the catalog entries under. If no catalog specified, store default catalog is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogEntryByParentPartNumbers(storeId: string, q: string, partNumber: Array<string>, langId?: string, catalogId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<CatalogEntryIBMAdminCatalogEntryDefiningAttributeDictionaryAttribute> {
            return localVarFp.catalogEntryByParentPartNumbers(storeId, q, partNumber, langId, catalogId, profileName, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets catalog entries by search term.
         * @summary Get catalog entries by search term
         * @param {string} storeId The store identifier.
         * @param {string} searchTerm The term user is searching for.
         * @param {Array<'Product' | 'Item' | 'CategoryItem' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>} [type] The catalog entry types to return.
         * @param {Array<'Product' | 'Item' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>} [excludeType] The catalog entry types that are not returned.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [catalogId] The unique id of the catalog to search the catalog entries under. If no catalog specified, store default catalog is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogEntryFindBySearchTerm(storeId: string, searchTerm: string, type?: Array<'Product' | 'Item' | 'CategoryItem' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>, excludeType?: Array<'Product' | 'Item' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>, langId?: string, catalogId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<CatalogEntryIBMAdminStandardOfferPrice> {
            return localVarFp.catalogEntryFindBySearchTerm(storeId, searchTerm, type, excludeType, langId, catalogId, profileName, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogEntryApi - object-oriented interface
 * @export
 * @class CatalogEntryApi
 * @extends {BaseAPI}
 */
export class CatalogEntryApi extends BaseAPI {
    /**
     * Finds catalog entries by their parent part number. Invalid part number is ignored.
     * @summary Get catalog entries by parent part
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {Array<string>} partNumber The part number of the parent catalog entry.
     * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
     * @param {string} [catalogId] The unique id of the catalog to search the catalog entries under. If no catalog specified, store default catalog is used.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogEntryApi
     */
    public catalogEntryByParentPartNumbers(storeId: string, q: string, partNumber: Array<string>, langId?: string, catalogId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return CatalogEntryApiFp(this.configuration).catalogEntryByParentPartNumbers(storeId, q, partNumber, langId, catalogId, profileName, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets catalog entries by search term.
     * @summary Get catalog entries by search term
     * @param {string} storeId The store identifier.
     * @param {string} searchTerm The term user is searching for.
     * @param {Array<'Product' | 'Item' | 'CategoryItem' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>} [type] The catalog entry types to return.
     * @param {Array<'Product' | 'Item' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>} [excludeType] The catalog entry types that are not returned.
     * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
     * @param {string} [catalogId] The unique id of the catalog to search the catalog entries under. If no catalog specified, store default catalog is used.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogEntryApi
     */
    public catalogEntryFindBySearchTerm(storeId: string, searchTerm: string, type?: Array<'Product' | 'Item' | 'CategoryItem' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>, excludeType?: Array<'Product' | 'Item' | 'Bundle' | 'Package' | 'DynamicKit' | 'PredDynaKit'>, langId?: string, catalogId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return CatalogEntryApiFp(this.configuration).catalogEntryFindBySearchTerm(storeId, searchTerm, type, excludeType, langId, catalogId, profileName, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}
