/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { CartCartUpdate } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { CartShippingInfo } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { CartUsableShippingInfo } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceOrderBeansUsableShipChargesAndAccountByShipModeDataBeanIBMUsableShipChargesByShipMode } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerShippingModes } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerShippingInfoHandlerUpdateShippingInfoBodyDescription } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * ShippingInfoApi - axios parameter creator
 * @export
 */
export const ShippingInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets shipping modes for the shopping cart.
         * @summary Get shipping modes
         * @param {string} storeId The store identifier.
         * @param {string} [locale] The locale to use for example, locale&#x3D;en_US. If the \&quot;langId\&quot; parameter is specified, the \&quot;locale\&quot; parameter is ignored. If no locale is specified, the store default locale is used.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetAllowableShippingModes: async (storeId: string, locale?: string, langId?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartGetAllowableShippingModes', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/shipping_modes`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets usable shipping charge information for the specified order ID.
         * @summary Get shipping charge
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetUsableShipChargesByShipMode: async (storeId: string, orderId: string, responseFormat?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartGetUsableShipChargesByShipMode', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cartGetUsableShipChargesByShipMode', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/cart/{orderId}/usable_ship_charges_by_ship_mode`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets shipping information for the shopping cart.
         * @summary Get shipping information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [orderId] The order ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetUsableShippingInfo: async (storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartGetUsableShippingInfo', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/usable_shipping_info`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets shipping information for the shopping cart.
         * @summary Get shipping instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [locale] The locale to use for example, locale&#x3D;en_US. If the \&quot;langId\&quot; parameter is specified, the \&quot;locale\&quot; parameter is ignored. If no locale is specified, the store default locale is used.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingInfoGetShippingInfo: async (storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, langId?: string, locale?: string, forUser?: string, forUserId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('shippingInfoGetShippingInfo', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/shipping_info`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }

            if (forUser !== undefined) {
                localVarQueryParameter['forUser'] = forUser;
            }

            if (forUserId !== undefined) {
                localVarQueryParameter['forUserId'] = forUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates shipping information for the shopping cart.
         * @summary Update shipping information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {ComIbmCommerceRestOrderHandlerShippingInfoHandlerUpdateShippingInfoBodyDescription} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingInfoUpdateOrderShippingInfo: async (storeId: string, responseFormat?: string, forUser?: string, forUserId?: string, body?: ComIbmCommerceRestOrderHandlerShippingInfoHandlerUpdateShippingInfoBodyDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('shippingInfoUpdateOrderShippingInfo', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/shipping_info`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (forUser !== undefined) {
                localVarQueryParameter['forUser'] = forUser;
            }

            if (forUserId !== undefined) {
                localVarQueryParameter['forUserId'] = forUserId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippingInfoApi - functional programming interface
 * @export
 */
export const ShippingInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShippingInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets shipping modes for the shopping cart.
         * @summary Get shipping modes
         * @param {string} storeId The store identifier.
         * @param {string} [locale] The locale to use for example, locale&#x3D;en_US. If the \&quot;langId\&quot; parameter is specified, the \&quot;locale\&quot; parameter is ignored. If no locale is specified, the store default locale is used.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartGetAllowableShippingModes(storeId: string, locale?: string, langId?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerShippingModes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartGetAllowableShippingModes(storeId, locale, langId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets usable shipping charge information for the specified order ID.
         * @summary Get shipping charge
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartGetUsableShipChargesByShipMode(storeId: string, orderId: string, responseFormat?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceOrderBeansUsableShipChargesAndAccountByShipModeDataBeanIBMUsableShipChargesByShipMode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartGetUsableShipChargesByShipMode(storeId, orderId, responseFormat, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets shipping information for the shopping cart.
         * @summary Get shipping information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [orderId] The order ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartGetUsableShippingInfo(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartUsableShippingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartGetUsableShippingInfo(storeId, responseFormat, pageNumber, pageSize, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets shipping information for the shopping cart.
         * @summary Get shipping instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [locale] The locale to use for example, locale&#x3D;en_US. If the \&quot;langId\&quot; parameter is specified, the \&quot;locale\&quot; parameter is ignored. If no locale is specified, the store default locale is used.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingInfoGetShippingInfo(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, langId?: string, locale?: string, forUser?: string, forUserId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartShippingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingInfoGetShippingInfo(storeId, responseFormat, pageNumber, pageSize, langId, locale, forUser, forUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates shipping information for the shopping cart.
         * @summary Update shipping information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {ComIbmCommerceRestOrderHandlerShippingInfoHandlerUpdateShippingInfoBodyDescription} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingInfoUpdateOrderShippingInfo(storeId: string, responseFormat?: string, forUser?: string, forUserId?: string, body?: ComIbmCommerceRestOrderHandlerShippingInfoHandlerUpdateShippingInfoBodyDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartCartUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingInfoUpdateOrderShippingInfo(storeId, responseFormat, forUser, forUserId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShippingInfoApi - factory interface
 * @export
 */
export const ShippingInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShippingInfoApiFp(configuration)
    return {
        /**
         * Gets shipping modes for the shopping cart.
         * @summary Get shipping modes
         * @param {string} storeId The store identifier.
         * @param {string} [locale] The locale to use for example, locale&#x3D;en_US. If the \&quot;langId\&quot; parameter is specified, the \&quot;locale\&quot; parameter is ignored. If no locale is specified, the store default locale is used.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetAllowableShippingModes(storeId: string, locale?: string, langId?: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerShippingModes> {
            return localVarFp.cartGetAllowableShippingModes(storeId, locale, langId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets usable shipping charge information for the specified order ID.
         * @summary Get shipping charge
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetUsableShipChargesByShipMode(storeId: string, orderId: string, responseFormat?: string, profileName?: string, options?: any): AxiosPromise<ComIbmCommerceOrderBeansUsableShipChargesAndAccountByShipModeDataBeanIBMUsableShipChargesByShipMode> {
            return localVarFp.cartGetUsableShipChargesByShipMode(storeId, orderId, responseFormat, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets shipping information for the shopping cart.
         * @summary Get shipping information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [orderId] The order ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetUsableShippingInfo(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options?: any): AxiosPromise<CartUsableShippingInfo> {
            return localVarFp.cartGetUsableShippingInfo(storeId, responseFormat, pageNumber, pageSize, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets shipping information for the shopping cart.
         * @summary Get shipping instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
         * @param {string} [locale] The locale to use for example, locale&#x3D;en_US. If the \&quot;langId\&quot; parameter is specified, the \&quot;locale\&quot; parameter is ignored. If no locale is specified, the store default locale is used.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingInfoGetShippingInfo(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, langId?: string, locale?: string, forUser?: string, forUserId?: string, options?: any): AxiosPromise<CartShippingInfo> {
            return localVarFp.shippingInfoGetShippingInfo(storeId, responseFormat, pageNumber, pageSize, langId, locale, forUser, forUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates shipping information for the shopping cart.
         * @summary Update shipping information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {ComIbmCommerceRestOrderHandlerShippingInfoHandlerUpdateShippingInfoBodyDescription} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingInfoUpdateOrderShippingInfo(storeId: string, responseFormat?: string, forUser?: string, forUserId?: string, body?: ComIbmCommerceRestOrderHandlerShippingInfoHandlerUpdateShippingInfoBodyDescription, options?: any): AxiosPromise<CartCartUpdate> {
            return localVarFp.shippingInfoUpdateOrderShippingInfo(storeId, responseFormat, forUser, forUserId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippingInfoApi - object-oriented interface
 * @export
 * @class ShippingInfoApi
 * @extends {BaseAPI}
 */
export class ShippingInfoApi extends BaseAPI {
    /**
     * Gets shipping modes for the shopping cart.
     * @summary Get shipping modes
     * @param {string} storeId The store identifier.
     * @param {string} [locale] The locale to use for example, locale&#x3D;en_US. If the \&quot;langId\&quot; parameter is specified, the \&quot;locale\&quot; parameter is ignored. If no locale is specified, the store default locale is used.
     * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingInfoApi
     */
    public cartGetAllowableShippingModes(storeId: string, locale?: string, langId?: string, responseFormat?: string, options?: any) {
        return ShippingInfoApiFp(this.configuration).cartGetAllowableShippingModes(storeId, locale, langId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets usable shipping charge information for the specified order ID.
     * @summary Get shipping charge
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingInfoApi
     */
    public cartGetUsableShipChargesByShipMode(storeId: string, orderId: string, responseFormat?: string, profileName?: string, options?: any) {
        return ShippingInfoApiFp(this.configuration).cartGetUsableShipChargesByShipMode(storeId, orderId, responseFormat, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets shipping information for the shopping cart.
     * @summary Get shipping information
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [orderId] The order ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingInfoApi
     */
    public cartGetUsableShippingInfo(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options?: any) {
        return ShippingInfoApiFp(this.configuration).cartGetUsableShippingInfo(storeId, responseFormat, pageNumber, pageSize, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets shipping information for the shopping cart.
     * @summary Get shipping instructions
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter is used. If \&quot;locale\&quot; is not specified, then the store default language is used.
     * @param {string} [locale] The locale to use for example, locale&#x3D;en_US. If the \&quot;langId\&quot; parameter is specified, the \&quot;locale\&quot; parameter is ignored. If no locale is specified, the store default locale is used.
     * @param {string} [forUser] User name to act on behalf of.
     * @param {string} [forUserId] User identifier to act on behalf of.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingInfoApi
     */
    public shippingInfoGetShippingInfo(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, langId?: string, locale?: string, forUser?: string, forUserId?: string, options?: any) {
        return ShippingInfoApiFp(this.configuration).shippingInfoGetShippingInfo(storeId, responseFormat, pageNumber, pageSize, langId, locale, forUser, forUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates shipping information for the shopping cart.
     * @summary Update shipping information
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [forUser] User name to act on behalf of.
     * @param {string} [forUserId] User identifier to act on behalf of.
     * @param {ComIbmCommerceRestOrderHandlerShippingInfoHandlerUpdateShippingInfoBodyDescription} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingInfoApi
     */
    public shippingInfoUpdateOrderShippingInfo(storeId: string, responseFormat?: string, forUser?: string, forUserId?: string, body?: ComIbmCommerceRestOrderHandlerShippingInfoHandlerUpdateShippingInfoBodyDescription, options?: any) {
        return ShippingInfoApiFp(this.configuration).shippingInfoUpdateOrderShippingInfo(storeId, responseFormat, forUser, forUserId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
