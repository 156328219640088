/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * CatalogDownloadApi - axios parameter creator
 * @export
 */
export const CatalogDownloadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download a catalog export file by export ID.
         * @param {string} storeId The store identifier.
         * @param {string} exportId The export ID from the catalog export REST API call.
         * @param {string} [deleted] The optional deleted flag. The value can be true or false. The default is false if it is not specified. If it is true, the file downloaded will only contains the deleted objects since last export.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadByExportId: async (storeId: string, exportId: string, deleted?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('downloadByExportId', 'storeId', storeId)
            // verify required parameter 'exportId' is not null or undefined
            assertParamExists('downloadByExportId', 'exportId', exportId)
            const localVarPath = `/store/{storeId}/catalog_download/byExportId/{exportId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"exportId"}}`, String(exportId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogDownloadApi - functional programming interface
 * @export
 */
export const CatalogDownloadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogDownloadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download a catalog export file by export ID.
         * @param {string} storeId The store identifier.
         * @param {string} exportId The export ID from the catalog export REST API call.
         * @param {string} [deleted] The optional deleted flag. The value can be true or false. The default is false if it is not specified. If it is true, the file downloaded will only contains the deleted objects since last export.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadByExportId(storeId: string, exportId: string, deleted?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadByExportId(storeId, exportId, deleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CatalogDownloadApi - factory interface
 * @export
 */
export const CatalogDownloadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogDownloadApiFp(configuration)
    return {
        /**
         * 
         * @summary Download a catalog export file by export ID.
         * @param {string} storeId The store identifier.
         * @param {string} exportId The export ID from the catalog export REST API call.
         * @param {string} [deleted] The optional deleted flag. The value can be true or false. The default is false if it is not specified. If it is true, the file downloaded will only contains the deleted objects since last export.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadByExportId(storeId: string, exportId: string, deleted?: string, options?: any): AxiosPromise<object> {
            return localVarFp.downloadByExportId(storeId, exportId, deleted, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogDownloadApi - object-oriented interface
 * @export
 * @class CatalogDownloadApi
 * @extends {BaseAPI}
 */
export class CatalogDownloadApi extends BaseAPI {
    /**
     * 
     * @summary Download a catalog export file by export ID.
     * @param {string} storeId The store identifier.
     * @param {string} exportId The export ID from the catalog export REST API call.
     * @param {string} [deleted] The optional deleted flag. The value can be true or false. The default is false if it is not specified. If it is true, the file downloaded will only contains the deleted objects since last export.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogDownloadApi
     */
    public downloadByExportId(storeId: string, exportId: string, deleted?: string, options?: any) {
        return CatalogDownloadApiFp(this.configuration).downloadByExportId(storeId, exportId, deleted, options).then((request) => request(this.axios, this.basePath));
    }
}
