/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceEmarketingBeansEmailUserReceiveDataBeanIBMOptOutAll } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonHandlerDeleteContextAttribute } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonHandlerResetPasswordAdministratorRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUser } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUserResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonHandlerUserIdentifier } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminUpdateRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationUpdateRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceUserBeansMemberRoleAssignDataBeanIBMRolesOfUserInOrgsICanAdmin } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceUserBeansUserSearchDataBeanIBMUserListDetails } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { PersonPerson } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * PersonApi - axios parameter creator
 * @export
 */
export const PersonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Changes language and currency preference in profile and in context.
         * @summary Change language and currency preference
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personChangeLanguageCurrency: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personChangeLanguageCurrency', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self/languageCurrency`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes one or more values of a context attribute for a registered user by removing associated entry in the MBRATTRVAL table.
         * @summary Delete context attribute values
         * @param {string} attributeName Name of the context attribute as listed in MBRATTR.
         * @param {string} value Value of the context attribute you want to delete as listed in MBRATTRVAL.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personDeleteContextAttributeForPerson: async (attributeName: string, value: string, storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'attributeName' is not null or undefined
            assertParamExists('personDeleteContextAttributeForPerson', 'attributeName', attributeName)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('personDeleteContextAttributeForPerson', 'value', value)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personDeleteContextAttributeForPerson', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self/contextattributes/{attributeName}/{value}`
                .replace(`{${"attributeName"}}`, String(attributeName))
                .replace(`{${"value"}}`, String(value))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows administrators to delete user
         * @summary Delete user (by administrator)
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personDeletePersonByAdmin: async (storeId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personDeletePersonByAdmin', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('personDeletePersonByAdmin', 'userId', userId)
            const localVarPath = `/store/{storeId}/person/{userId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"userId"}}`, String(userId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows administrators to find users based on a query name. See each query for details on input and output.
         * @summary Get user by query
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [startIndex] The starting index of the result.
         * @param {string} [maxResults] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personFindByQuery: async (storeId: string, q: string, startIndex?: string, maxResults?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personFindByQuery', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('personFindByQuery', 'q', q)
            const localVarPath = `/store/{storeId}/person`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows administrators to find user information by user identifier.
         * @summary Get user information by identifier
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. The default profile name is IBM_User_Display_Details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personFindByUserIdWRolesOfUserInOrgsICanAdminProfileName: async (storeId: string, userId: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personFindByUserIdWRolesOfUserInOrgsICanAdminProfileName', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('personFindByUserIdWRolesOfUserInOrgsICanAdminProfileName', 'userId', userId)
            const localVarPath = `/store/{storeId}/person/{userId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"userId"}}`, String(userId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finds personal opt-out. When the store level personal opt-out does not exist, the site level personal opt-out is returned.
         * @summary Get opt out preferences
         * @param {string} storeId The store identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.  Default profile name &#x3D; IBM_optOut_email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personFindOptOutBySelfWOptOutAllProfileName: async (storeId: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personFindOptOutBySelfWOptOutAllProfileName', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self/optOut`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the account data for a registered user.
         * @summary Get account data
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personFindPersonBySelf: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personFindPersonBySelf', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Performs an action on a user by an administrator. See each action for details on input and output.
         * @summary Performs an action on a user by an administrator. See each action for details on input and output.
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {string} action The action of the rest service. The assignRole action allows an administrator to assign role(s) to a registered user, while unassignRole allos an administrator to unassign role(s) from a registered user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPerformActionByAdmin: async (storeId: string, userId: string, action: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personPerformActionByAdmin', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('personPerformActionByAdmin', 'userId', userId)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('personPerformActionByAdmin', 'action', action)
            const localVarPath = `/store/{storeId}/person/{userId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"userId"}}`, String(userId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a user. When the mode is set to admin, the registration is done by an administrator.
         * @summary Register user
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [mode] The mode of the rest service.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personRegisterPersonOnUserRegistrationAdminAdd: async (storeId: string, responseFormat?: string, mode?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personRegisterPersonOnUserRegistrationAdminAdd', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows a CSR or CSS to reset the password for a registered user. It also allows resetting password when the CSR / CSS has established a session to act on behalf of a user.
         * @summary Reset password
         * @param {string} storeId The store identifier.
         * @param {string} [mode] The mode in which resetPassword is run. ResetPassword can be executed in an administrator session or in an on-behalf session for a user. Default value is \&#39;resetPasswordAdmin\&#39; if no valid value was supplied.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerResetPasswordAdministratorRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personResetPasswordByAdmin: async (storeId: string, mode?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerResetPasswordAdministratorRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personResetPasswordByAdmin', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/updateMemberPassword`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the user to include, exclude, or unassign the user from a member group.
         * @summary Update member group assignment
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUser} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personUpdateMemberUser: async (storeId: string, userId: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personUpdateMemberUser', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('personUpdateMemberUser', 'userId', userId)
            const localVarPath = `/store/{storeId}/person/updateMemberUser/{userId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"userId"}}`, String(userId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows administrators to update account data for a registered user.
         * @summary Update account data
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminUpdateRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personUpdatePersonByAdmin: async (storeId: string, userId: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personUpdatePersonByAdmin', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('personUpdatePersonByAdmin', 'userId', userId)
            const localVarPath = `/store/{storeId}/person/{userId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"userId"}}`, String(userId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates account data for a registered user. This also supports resetting password for unauthenticated and authenticated users. When action is set to \'updateUserRegistration\', user account data is updated using UserRegistrationUpdateCmd
         * @summary Update account data
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [action] The action of the rest service.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationUpdateRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personUpdatePersonOnUserRegistrationUpdate: async (storeId: string, responseFormat?: string, action?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('personUpdatePersonOnUserRegistrationUpdate', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes one or more values of a context attribute for a registered user.
         * @param {string} jobNames Name of the person\&#39;s job function to use for the request
         * @param {string} values Value of the person\&#39;s job function..
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPersonSelfContextattributesJobNamesValuesDelete: async (jobNames: string, values: string, storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobNames' is not null or undefined
            assertParamExists('storeStoreIdPersonSelfContextattributesJobNamesValuesDelete', 'jobNames', jobNames)
            // verify required parameter 'values' is not null or undefined
            assertParamExists('storeStoreIdPersonSelfContextattributesJobNamesValuesDelete', 'values', values)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPersonSelfContextattributesJobNamesValuesDelete', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self/contextattributes/{jobNames}/{values}`
                .replace(`{${"jobNames"}}`, String(jobNames))
                .replace(`{${"values"}}`, String(values))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonApi - functional programming interface
 * @export
 */
export const PersonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonApiAxiosParamCreator(configuration)
    return {
        /**
         * Changes language and currency preference in profile and in context.
         * @summary Change language and currency preference
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personChangeLanguageCurrency(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personChangeLanguageCurrency(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes one or more values of a context attribute for a registered user by removing associated entry in the MBRATTRVAL table.
         * @summary Delete context attribute values
         * @param {string} attributeName Name of the context attribute as listed in MBRATTR.
         * @param {string} value Value of the context attribute you want to delete as listed in MBRATTRVAL.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personDeleteContextAttributeForPerson(attributeName: string, value: string, storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerDeleteContextAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personDeleteContextAttributeForPerson(attributeName, value, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows administrators to delete user
         * @summary Delete user (by administrator)
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personDeletePersonByAdmin(storeId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerUserIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personDeletePersonByAdmin(storeId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows administrators to find users based on a query name. See each query for details on input and output.
         * @summary Get user by query
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [startIndex] The starting index of the result.
         * @param {string} [maxResults] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personFindByQuery(storeId: string, q: string, startIndex?: string, maxResults?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceUserBeansUserSearchDataBeanIBMUserListDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personFindByQuery(storeId, q, startIndex, maxResults, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows administrators to find user information by user identifier.
         * @summary Get user information by identifier
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. The default profile name is IBM_User_Display_Details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personFindByUserIdWRolesOfUserInOrgsICanAdminProfileName(storeId: string, userId: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceUserBeansMemberRoleAssignDataBeanIBMRolesOfUserInOrgsICanAdmin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personFindByUserIdWRolesOfUserInOrgsICanAdminProfileName(storeId, userId, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Finds personal opt-out. When the store level personal opt-out does not exist, the site level personal opt-out is returned.
         * @summary Get opt out preferences
         * @param {string} storeId The store identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.  Default profile name &#x3D; IBM_optOut_email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personFindOptOutBySelfWOptOutAllProfileName(storeId: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceEmarketingBeansEmailUserReceiveDataBeanIBMOptOutAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personFindOptOutBySelfWOptOutAllProfileName(storeId, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the account data for a registered user.
         * @summary Get account data
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personFindPersonBySelf(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonPerson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personFindPersonBySelf(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Performs an action on a user by an administrator. See each action for details on input and output.
         * @summary Performs an action on a user by an administrator. See each action for details on input and output.
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {string} action The action of the rest service. The assignRole action allows an administrator to assign role(s) to a registered user, while unassignRole allos an administrator to unassign role(s) from a registered user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personPerformActionByAdmin(storeId: string, userId: string, action: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personPerformActionByAdmin(storeId, userId, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers a user. When the mode is set to admin, the registration is done by an administrator.
         * @summary Register user
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [mode] The mode of the rest service.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personRegisterPersonOnUserRegistrationAdminAdd(storeId: string, responseFormat?: string, mode?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personRegisterPersonOnUserRegistrationAdminAdd(storeId, responseFormat, mode, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows a CSR or CSS to reset the password for a registered user. It also allows resetting password when the CSR / CSS has established a session to act on behalf of a user.
         * @summary Reset password
         * @param {string} storeId The store identifier.
         * @param {string} [mode] The mode in which resetPassword is run. ResetPassword can be executed in an administrator session or in an on-behalf session for a user. Default value is \&#39;resetPasswordAdmin\&#39; if no valid value was supplied.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerResetPasswordAdministratorRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personResetPasswordByAdmin(storeId: string, mode?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerResetPasswordAdministratorRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personResetPasswordByAdmin(storeId, mode, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the user to include, exclude, or unassign the user from a member group.
         * @summary Update member group assignment
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUser} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personUpdateMemberUser(storeId: string, userId: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personUpdateMemberUser(storeId, userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows administrators to update account data for a registered user.
         * @summary Update account data
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminUpdateRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personUpdatePersonByAdmin(storeId: string, userId: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerUserIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personUpdatePersonByAdmin(storeId, userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates account data for a registered user. This also supports resetting password for unauthenticated and authenticated users. When action is set to \'updateUserRegistration\', user account data is updated using UserRegistrationUpdateCmd
         * @summary Update account data
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [action] The action of the rest service.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationUpdateRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personUpdatePersonOnUserRegistrationUpdate(storeId: string, responseFormat?: string, action?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonPerson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personUpdatePersonOnUserRegistrationUpdate(storeId, responseFormat, action, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes one or more values of a context attribute for a registered user.
         * @param {string} jobNames Name of the person\&#39;s job function to use for the request
         * @param {string} values Value of the person\&#39;s job function..
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPersonSelfContextattributesJobNamesValuesDelete(jobNames: string, values: string, storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerDeleteContextAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPersonSelfContextattributesJobNamesValuesDelete(jobNames, values, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonApi - factory interface
 * @export
 */
export const PersonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonApiFp(configuration)
    return {
        /**
         * Changes language and currency preference in profile and in context.
         * @summary Change language and currency preference
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personChangeLanguageCurrency(storeId: string, responseFormat?: string, options?: any): AxiosPromise<object> {
            return localVarFp.personChangeLanguageCurrency(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes one or more values of a context attribute for a registered user by removing associated entry in the MBRATTRVAL table.
         * @summary Delete context attribute values
         * @param {string} attributeName Name of the context attribute as listed in MBRATTR.
         * @param {string} value Value of the context attribute you want to delete as listed in MBRATTRVAL.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personDeleteContextAttributeForPerson(attributeName: string, value: string, storeId: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerDeleteContextAttribute> {
            return localVarFp.personDeleteContextAttributeForPerson(attributeName, value, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows administrators to delete user
         * @summary Delete user (by administrator)
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personDeletePersonByAdmin(storeId: string, userId: string, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerUserIdentifier> {
            return localVarFp.personDeletePersonByAdmin(storeId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows administrators to find users based on a query name. See each query for details on input and output.
         * @summary Get user by query
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [startIndex] The starting index of the result.
         * @param {string} [maxResults] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personFindByQuery(storeId: string, q: string, startIndex?: string, maxResults?: string, options?: any): AxiosPromise<ComIbmCommerceUserBeansUserSearchDataBeanIBMUserListDetails> {
            return localVarFp.personFindByQuery(storeId, q, startIndex, maxResults, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows administrators to find user information by user identifier.
         * @summary Get user information by identifier
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. The default profile name is IBM_User_Display_Details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personFindByUserIdWRolesOfUserInOrgsICanAdminProfileName(storeId: string, userId: string, profileName?: string, options?: any): AxiosPromise<ComIbmCommerceUserBeansMemberRoleAssignDataBeanIBMRolesOfUserInOrgsICanAdmin> {
            return localVarFp.personFindByUserIdWRolesOfUserInOrgsICanAdminProfileName(storeId, userId, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Finds personal opt-out. When the store level personal opt-out does not exist, the site level personal opt-out is returned.
         * @summary Get opt out preferences
         * @param {string} storeId The store identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.  Default profile name &#x3D; IBM_optOut_email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personFindOptOutBySelfWOptOutAllProfileName(storeId: string, profileName?: string, options?: any): AxiosPromise<ComIbmCommerceEmarketingBeansEmailUserReceiveDataBeanIBMOptOutAll> {
            return localVarFp.personFindOptOutBySelfWOptOutAllProfileName(storeId, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the account data for a registered user.
         * @summary Get account data
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personFindPersonBySelf(storeId: string, responseFormat?: string, options?: any): AxiosPromise<PersonPerson> {
            return localVarFp.personFindPersonBySelf(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Performs an action on a user by an administrator. See each action for details on input and output.
         * @summary Performs an action on a user by an administrator. See each action for details on input and output.
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {string} action The action of the rest service. The assignRole action allows an administrator to assign role(s) to a registered user, while unassignRole allos an administrator to unassign role(s) from a registered user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPerformActionByAdmin(storeId: string, userId: string, action: string, options?: any): AxiosPromise<void> {
            return localVarFp.personPerformActionByAdmin(storeId, userId, action, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a user. When the mode is set to admin, the registration is done by an administrator.
         * @summary Register user
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [mode] The mode of the rest service.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personRegisterPersonOnUserRegistrationAdminAdd(storeId: string, responseFormat?: string, mode?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddRequest, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddResponse> {
            return localVarFp.personRegisterPersonOnUserRegistrationAdminAdd(storeId, responseFormat, mode, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows a CSR or CSS to reset the password for a registered user. It also allows resetting password when the CSR / CSS has established a session to act on behalf of a user.
         * @summary Reset password
         * @param {string} storeId The store identifier.
         * @param {string} [mode] The mode in which resetPassword is run. ResetPassword can be executed in an administrator session or in an on-behalf session for a user. Default value is \&#39;resetPasswordAdmin\&#39; if no valid value was supplied.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerResetPasswordAdministratorRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personResetPasswordByAdmin(storeId: string, mode?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerResetPasswordAdministratorRequest, options?: any): AxiosPromise<object> {
            return localVarFp.personResetPasswordByAdmin(storeId, mode, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the user to include, exclude, or unassign the user from a member group.
         * @summary Update member group assignment
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUser} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personUpdateMemberUser(storeId: string, userId: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUser, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUserResponse> {
            return localVarFp.personUpdateMemberUser(storeId, userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows administrators to update account data for a registered user.
         * @summary Update account data
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminUpdateRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personUpdatePersonByAdmin(storeId: string, userId: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminUpdateRequest, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerUserIdentifier> {
            return localVarFp.personUpdatePersonByAdmin(storeId, userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates account data for a registered user. This also supports resetting password for unauthenticated and authenticated users. When action is set to \'updateUserRegistration\', user account data is updated using UserRegistrationUpdateCmd
         * @summary Update account data
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [action] The action of the rest service.
         * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationUpdateRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personUpdatePersonOnUserRegistrationUpdate(storeId: string, responseFormat?: string, action?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationUpdateRequest, options?: any): AxiosPromise<PersonPerson> {
            return localVarFp.personUpdatePersonOnUserRegistrationUpdate(storeId, responseFormat, action, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes one or more values of a context attribute for a registered user.
         * @param {string} jobNames Name of the person\&#39;s job function to use for the request
         * @param {string} values Value of the person\&#39;s job function..
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPersonSelfContextattributesJobNamesValuesDelete(jobNames: string, values: string, storeId: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerPersonHandlerDeleteContextAttribute> {
            return localVarFp.storeStoreIdPersonSelfContextattributesJobNamesValuesDelete(jobNames, values, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonApi - object-oriented interface
 * @export
 * @class PersonApi
 * @extends {BaseAPI}
 */
export class PersonApi extends BaseAPI {
    /**
     * Changes language and currency preference in profile and in context.
     * @summary Change language and currency preference
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personChangeLanguageCurrency(storeId: string, responseFormat?: string, options?: any) {
        return PersonApiFp(this.configuration).personChangeLanguageCurrency(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes one or more values of a context attribute for a registered user by removing associated entry in the MBRATTRVAL table.
     * @summary Delete context attribute values
     * @param {string} attributeName Name of the context attribute as listed in MBRATTR.
     * @param {string} value Value of the context attribute you want to delete as listed in MBRATTRVAL.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personDeleteContextAttributeForPerson(attributeName: string, value: string, storeId: string, responseFormat?: string, options?: any) {
        return PersonApiFp(this.configuration).personDeleteContextAttributeForPerson(attributeName, value, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows administrators to delete user
     * @summary Delete user (by administrator)
     * @param {string} storeId The store identifier.
     * @param {string} userId The user identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personDeletePersonByAdmin(storeId: string, userId: string, options?: any) {
        return PersonApiFp(this.configuration).personDeletePersonByAdmin(storeId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows administrators to find users based on a query name. See each query for details on input and output.
     * @summary Get user by query
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {string} [startIndex] The starting index of the result.
     * @param {string} [maxResults] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personFindByQuery(storeId: string, q: string, startIndex?: string, maxResults?: string, options?: any) {
        return PersonApiFp(this.configuration).personFindByQuery(storeId, q, startIndex, maxResults, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows administrators to find user information by user identifier.
     * @summary Get user information by identifier
     * @param {string} storeId The store identifier.
     * @param {string} userId The user identifier.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. The default profile name is IBM_User_Display_Details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personFindByUserIdWRolesOfUserInOrgsICanAdminProfileName(storeId: string, userId: string, profileName?: string, options?: any) {
        return PersonApiFp(this.configuration).personFindByUserIdWRolesOfUserInOrgsICanAdminProfileName(storeId, userId, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Finds personal opt-out. When the store level personal opt-out does not exist, the site level personal opt-out is returned.
     * @summary Get opt out preferences
     * @param {string} storeId The store identifier.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.  Default profile name &#x3D; IBM_optOut_email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personFindOptOutBySelfWOptOutAllProfileName(storeId: string, profileName?: string, options?: any) {
        return PersonApiFp(this.configuration).personFindOptOutBySelfWOptOutAllProfileName(storeId, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the account data for a registered user.
     * @summary Get account data
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personFindPersonBySelf(storeId: string, responseFormat?: string, options?: any) {
        return PersonApiFp(this.configuration).personFindPersonBySelf(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Performs an action on a user by an administrator. See each action for details on input and output.
     * @summary Performs an action on a user by an administrator. See each action for details on input and output.
     * @param {string} storeId The store identifier.
     * @param {string} userId The user identifier.
     * @param {string} action The action of the rest service. The assignRole action allows an administrator to assign role(s) to a registered user, while unassignRole allos an administrator to unassign role(s) from a registered user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personPerformActionByAdmin(storeId: string, userId: string, action: string, options?: any) {
        return PersonApiFp(this.configuration).personPerformActionByAdmin(storeId, userId, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers a user. When the mode is set to admin, the registration is done by an administrator.
     * @summary Register user
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [mode] The mode of the rest service.
     * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddRequest} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personRegisterPersonOnUserRegistrationAdminAdd(storeId: string, responseFormat?: string, mode?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminAddRequest, options?: any) {
        return PersonApiFp(this.configuration).personRegisterPersonOnUserRegistrationAdminAdd(storeId, responseFormat, mode, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows a CSR or CSS to reset the password for a registered user. It also allows resetting password when the CSR / CSS has established a session to act on behalf of a user.
     * @summary Reset password
     * @param {string} storeId The store identifier.
     * @param {string} [mode] The mode in which resetPassword is run. ResetPassword can be executed in an administrator session or in an on-behalf session for a user. Default value is \&#39;resetPasswordAdmin\&#39; if no valid value was supplied.
     * @param {ComIbmCommerceRestMemberHandlerPersonHandlerResetPasswordAdministratorRequest} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personResetPasswordByAdmin(storeId: string, mode?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerResetPasswordAdministratorRequest, options?: any) {
        return PersonApiFp(this.configuration).personResetPasswordByAdmin(storeId, mode, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the user to include, exclude, or unassign the user from a member group.
     * @summary Update member group assignment
     * @param {string} storeId The store identifier.
     * @param {string} userId The user identifier.
     * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUser} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personUpdateMemberUser(storeId: string, userId: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUpdateMemberUser, options?: any) {
        return PersonApiFp(this.configuration).personUpdateMemberUser(storeId, userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows administrators to update account data for a registered user.
     * @summary Update account data
     * @param {string} storeId The store identifier.
     * @param {string} userId The user identifier.
     * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminUpdateRequest} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personUpdatePersonByAdmin(storeId: string, userId: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationAdminUpdateRequest, options?: any) {
        return PersonApiFp(this.configuration).personUpdatePersonByAdmin(storeId, userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates account data for a registered user. This also supports resetting password for unauthenticated and authenticated users. When action is set to \'updateUserRegistration\', user account data is updated using UserRegistrationUpdateCmd
     * @summary Update account data
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [action] The action of the rest service.
     * @param {ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationUpdateRequest} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personUpdatePersonOnUserRegistrationUpdate(storeId: string, responseFormat?: string, action?: string, body?: ComIbmCommerceRestMemberHandlerPersonHandlerUserRegistrationUpdateRequest, options?: any) {
        return PersonApiFp(this.configuration).personUpdatePersonOnUserRegistrationUpdate(storeId, responseFormat, action, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes one or more values of a context attribute for a registered user.
     * @param {string} jobNames Name of the person\&#39;s job function to use for the request
     * @param {string} values Value of the person\&#39;s job function..
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public storeStoreIdPersonSelfContextattributesJobNamesValuesDelete(jobNames: string, values: string, storeId: string, responseFormat?: string, options?: any) {
        return PersonApiFp(this.configuration).storeStoreIdPersonSelfContextattributesJobNamesValuesDelete(jobNames, values, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
