/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
import { UpdateItemsShippingAddressActionType } from "solteq/src/types/order/order.types";
//Redux
import * as ACTIONTYPES from "../action-types/order";

const ADD_ITEM_ACTION = createAction<any, string>(ACTIONTYPES.ITEM_ADD_REQUESTED);
const REMOVE_ITEM_ACTION = createAction<any, string>(ACTIONTYPES.ITEM_REMOVE_REQUESTED);
const UPDATE_ITEM_ACTION = createAction<any, string>(ACTIONTYPES.ITEM_UPDATE_REQUESTED);
const REMOVE_ITEMS_ACTION = createAction<any, string>(ACTIONTYPES.ITEMS_REMOVE_REQUESTED);
const COPY_ITEMS_ACTION = createAction<any, string>(ACTIONTYPES.ITEMS_COPY_REQUESTED);
const UPDATE_ITEMS_SHIPPING_ADDRESS_ACTION = createAction<UpdateItemsShippingAddressActionType, string>(
  ACTIONTYPES.UPDATE_ITEMS_SHIPPING_ADDRESS_REQUESTED
);
const BULKUPDATE_SHIPPING_DATES_ACTION = createAction<any, string>(ACTIONTYPES.BULKUPDATE_SHIPPING_DATES_REQUESTED);
/**
 * The action set fetching state to true.
 */
const FETCHING_CART_ACTION = createAction<any, string>(ACTIONTYPES.CART_FETCHING_REQUESTED);
const GET_CART_ACTION = createAction<any, string>(ACTIONTYPES.CART_GET_REQUESTED);
const COPY_CART_ACTION = createAction<any, string>(ACTIONTYPES.COPY_CART);
const COPY_CART_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.COPY_CART_SUCCESS);
const COPY_CART_ERROR_ACTION = createAction<any, string>(ACTIONTYPES.COPY_CART_ERROR);

const GET_SHIPINFO_ACTION = createAction<any, string>(ACTIONTYPES.SHIPINFO_GET_REQUESTED);

const GET_SHIPMODES_ACTION = createAction<any, string>(ACTIONTYPES.SHIPMODES_GET_REQUESTED);

const UPDATE_SHIPMODE_ACTION = createAction<any, string>(ACTIONTYPES.SHIPMODE_UPDATE_REQUESTED);

const GET_PAYMETHODS_ACTION = createAction<any, string>(ACTIONTYPES.PAYMETHODS_GET_REQUESTED);

const RESET_CART_ACTION = createAction(ACTIONTYPES.CART_RESET_REQUESTED);

const CHECK_OUT_ACTION = createAction<any, string>(ACTIONTYPES.CHECKOUT_REQUESTED);
const TOGGLE_ORDER_BAN_ACTION = createAction(ACTIONTYPES.TOGGLE_ORDER_BAN);

const UPDATE_ITEMS_PRICE = createAction<any, string>(ACTIONTYPES.UPDATE_ITEMS_PRICE_REQUESTED);
const FETCH_ALL_ORDERS_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_ALL_ORDERS);
const FETCH_ORDERS_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_ALL_ORDERS_SUCCESS);

const FETCH_ORDERS_ERROR_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_ALL_ORDERS_ERROR);

const FETCH_ALLOWABLE_SHIPMODES_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_ALLOWABLE_SHIPMODES);
const FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALLOWABLE_SHIPMODES_SUCCESS
);
const FETCH_ALLOWABLE_SHIPMODES_ERROR_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_ALLOWABLE_SHIPMODES_ERROR);

const SET_ACTIVE_INPROGRESS_ORDER_ACTION = createAction<any, string>(ACTIONTYPES.SET_ACTIVE_INPROGRESS_ORDER);

const RESET_ACTIVE_INPROGRESS_ORDER_ACTION = createAction(ACTIONTYPES.RESET_ACTIVE_INPROGRESS_ORDER);

const FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM
);

const FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS
);

const FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR
);

const REMOVE_INPROGRESS_ORDER_ITEM_ACTION = createAction<any, string>(ACTIONTYPES.REMOVE_INPROGRESS_ORDER_ITEM);
const UPDATE_INPROGRESS_ORDER_ITEM_ACTION = createAction<any, string>(ACTIONTYPES.UPDATE_INPROGRESS_ORDER_ITEM);

const FETCH_ALLOWABLE_PAYMETHODS_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_ALLOWABLE_PAYMETHODS);
const FETCH_ALLOWABLE_PAYMETHODS_S_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_ALLOWABLE_PAYMETHODS_S);
const FETCH_ALLOWABLE_PAYMETHODS_F_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_ALLOWABLE_PAYMETHODS_F);

const FETCH_ORDER_LINES_CLEAR_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_ORDER_LINES_CLEAR);
const FETCH_OPEN_ORDER_LINES_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_OPEN_ORDER_LINES);
const FETCH_OPEN_ORDER_LINES_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_OPEN_ORDER_LINES_SUCCESS);
const FETCH_OPEN_ORDER_LINES_ERROR_ACTION = createAction<any, string>(ACTIONTYPES.FETCH_OPEN_ORDER_LINES_ERROR);

const UPDATE_SHIPPING_INFO_ACTION = createAction<any, string>(ACTIONTYPES.UPDATE_SHIPPING_INFO);

const ORDER_METHOD_SET_PICKUP_ACTION = createAction<any, string>(ACTIONTYPES.ORDER_METHOD_SET_PICKUP);
const ORDER_METHOD_RESET_ACTION = createAction<any, string>(ACTIONTYPES.ORDER_METHOD_RESET);

const SET_PICKUP_PERSON_ACTION = createAction<any, string>(ACTIONTYPES.SET_PICKUP_PERSON);

const GET_PAYTRAIL_PAYMETHODS_ACTION = createAction<any, string>(ACTIONTYPES.PAYTRAIL_PAYMETHODS_GET_REQUESTED);
const GET_PAYTRAIL_PAYMETHODS_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.PAYTRAIL_PAYMETHODS_GET_SUCCESS);
const PAYTRAIL_METHOD_SELECTED_ACTION = createAction<any, string>(ACTIONTYPES.PAYTRAIL_METHOD_SELECTED);

export {
  ADD_ITEM_ACTION,
  BULKUPDATE_SHIPPING_DATES_ACTION,
  CHECK_OUT_ACTION,
  COPY_CART_ACTION,
  COPY_CART_ERROR_ACTION,
  COPY_CART_SUCCESS_ACTION,
  COPY_ITEMS_ACTION,
  FETCHING_CART_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_F_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_S_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_ERROR_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION,
  FETCH_ALL_ORDERS_ACTION,
  FETCH_OPEN_ORDER_LINES_ACTION,
  FETCH_OPEN_ORDER_LINES_ERROR_ACTION,
  FETCH_OPEN_ORDER_LINES_SUCCESS_ACTION,
  FETCH_ORDERS_ERROR_ACTION,
  FETCH_ORDERS_SUCCESS_ACTION,
  FETCH_ORDER_LINES_CLEAR_ACTION,
  GET_CART_ACTION,
  GET_PAYMETHODS_ACTION,
  GET_SHIPINFO_ACTION,
  GET_SHIPMODES_ACTION,
  REMOVE_INPROGRESS_ORDER_ITEM_ACTION,
  REMOVE_ITEMS_ACTION,
  REMOVE_ITEM_ACTION,
  RESET_ACTIVE_INPROGRESS_ORDER_ACTION,
  RESET_CART_ACTION,
  SET_ACTIVE_INPROGRESS_ORDER_ACTION,
  TOGGLE_ORDER_BAN_ACTION,
  UPDATE_INPROGRESS_ORDER_ITEM_ACTION,
  UPDATE_ITEMS_PRICE,
  UPDATE_ITEMS_SHIPPING_ADDRESS_ACTION,
  UPDATE_ITEM_ACTION,
  UPDATE_SHIPMODE_ACTION,
  UPDATE_SHIPPING_INFO_ACTION,
  ORDER_METHOD_SET_PICKUP_ACTION,
  ORDER_METHOD_RESET_ACTION,
  SET_PICKUP_PERSON_ACTION,
  GET_PAYTRAIL_PAYMETHODS_ACTION,
  GET_PAYTRAIL_PAYMETHODS_SUCCESS_ACTION,
  PAYTRAIL_METHOD_SELECTED_ACTION,
};
