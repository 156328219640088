/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserRequestBody } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerUserActivateHandlerResendActiveUserRequestBody } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * UserActivateApi - axios parameter creator
 * @export
 */
export const UserActivateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Activate the user account.
         * @summary Activate the user account.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserRequestBody} body Request body to activate the user account.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [langId] The language ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userActivateActiveUser: async (storeId: string, body: ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserRequestBody, responseFormat?: string, langId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('userActivateActiveUser', 'storeId', storeId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('userActivateActiveUser', 'body', body)
            const localVarPath = `/store/{storeId}/useractivate/emailactivate`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend user account activation email.
         * @summary Resend user account activation email.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerUserActivateHandlerResendActiveUserRequestBody} body Request body for resend user account activation email.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userActivateResendActiveUser: async (storeId: string, body: ComIbmCommerceRestMemberHandlerUserActivateHandlerResendActiveUserRequestBody, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('userActivateResendActiveUser', 'storeId', storeId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('userActivateResendActiveUser', 'body', body)
            const localVarPath = `/store/{storeId}/useractivate/resendemail`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserActivateApi - functional programming interface
 * @export
 */
export const UserActivateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserActivateApiAxiosParamCreator(configuration)
    return {
        /**
         * Activate the user account.
         * @summary Activate the user account.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserRequestBody} body Request body to activate the user account.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [langId] The language ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userActivateActiveUser(storeId: string, body: ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserRequestBody, responseFormat?: string, langId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userActivateActiveUser(storeId, body, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resend user account activation email.
         * @summary Resend user account activation email.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerUserActivateHandlerResendActiveUserRequestBody} body Request body for resend user account activation email.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userActivateResendActiveUser(storeId: string, body: ComIbmCommerceRestMemberHandlerUserActivateHandlerResendActiveUserRequestBody, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userActivateResendActiveUser(storeId, body, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserActivateApi - factory interface
 * @export
 */
export const UserActivateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserActivateApiFp(configuration)
    return {
        /**
         * Activate the user account.
         * @summary Activate the user account.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserRequestBody} body Request body to activate the user account.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [langId] The language ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userActivateActiveUser(storeId: string, body: ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserRequestBody, responseFormat?: string, langId?: string, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserResponse> {
            return localVarFp.userActivateActiveUser(storeId, body, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
        /**
         * Resend user account activation email.
         * @summary Resend user account activation email.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerUserActivateHandlerResendActiveUserRequestBody} body Request body for resend user account activation email.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userActivateResendActiveUser(storeId: string, body: ComIbmCommerceRestMemberHandlerUserActivateHandlerResendActiveUserRequestBody, responseFormat?: string, options?: any): AxiosPromise<object> {
            return localVarFp.userActivateResendActiveUser(storeId, body, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserActivateApi - object-oriented interface
 * @export
 * @class UserActivateApi
 * @extends {BaseAPI}
 */
export class UserActivateApi extends BaseAPI {
    /**
     * Activate the user account.
     * @summary Activate the user account.
     * @param {string} storeId The store identifier.
     * @param {ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserRequestBody} body Request body to activate the user account.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [langId] The language ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserActivateApi
     */
    public userActivateActiveUser(storeId: string, body: ComIbmCommerceRestMemberHandlerUserActivateHandlerActiveUserRequestBody, responseFormat?: string, langId?: string, options?: any) {
        return UserActivateApiFp(this.configuration).userActivateActiveUser(storeId, body, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resend user account activation email.
     * @summary Resend user account activation email.
     * @param {string} storeId The store identifier.
     * @param {ComIbmCommerceRestMemberHandlerUserActivateHandlerResendActiveUserRequestBody} body Request body for resend user account activation email.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserActivateApi
     */
    public userActivateResendActiveUser(storeId: string, body: ComIbmCommerceRestMemberHandlerUserActivateHandlerResendActiveUserRequestBody, responseFormat?: string, options?: any) {
        return UserActivateApiFp(this.configuration).userActivateResendActiveUser(storeId, body, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
