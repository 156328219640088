import moment from "moment";

export function isInvalidDateForSelection(
  value: Date | null,
  disablePast: boolean = false,
  minDate?: Date | null
): boolean {
  if (!(value instanceof Date && !isNaN(value as any))) {
    return true;
  }
  if (disablePast || minDate) {
    return isDateBeforeMinimum(value, minDate) || !value;
  }

  return !value;
}

/** Checks if given date is below minimun date. If min date npt given, uses the current date as default. */
export function isDateBeforeMinimum(value: Date | null, minDate?: Date | null): boolean {
  return moment(value).isBefore(minDate ?? new Date(), "day");
}
