/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AddressBookTC } from './address-book-tc';
import { AddressReference } from './address-reference';
import { AttributeDetail } from './attribute-detail';
import { BusinessPolicyRef } from './business-policy-ref';
import { ExtendedTC } from './extended-tc';
import { Owner } from './owner';
import { POTCBlanket } from './potcblanket';
import { POTCIndividual } from './potcindividual';
import { POTCLimited } from './potclimited';
import { Participant } from './participant';
import { PaymentMethodDisplayString } from './payment-method-display-string';
import { PaymentTC } from './payment-tc';
import { PaymentTCAllOfBillToAddress } from './payment-tcall-of-bill-to-address';
import { PaymentTCAllOfPaymentMethod } from './payment-tcall-of-payment-method';
import { Property } from './property';
import { ShippingTCShipToAddress } from './shipping-tcship-to-address';
import { ShippingTCShippingCharge } from './shipping-tcshipping-charge';
import { ShippingTCShippingChargeAdjustment } from './shipping-tcshipping-charge-adjustment';
import { ShippingTCShippingMode } from './shipping-tcshipping-mode';
import { StoreRef } from './store-ref';
import { TermConditionDescription } from './term-condition-description';

/**
 * 
 * @export
 * @interface AccountTermCondition
 */
export interface AccountTermCondition {
    /**
     * The full entity class name of the extended term and condition.
     * @type {string}
     * @memberof AccountTermCondition
     */
    _class: AccountTermConditionClassEnum;
    /**
     * The additional properties of the term and condtion.
     * @type {Array<Property>}
     * @memberof AccountTermCondition
     */
    properties?: Array<Property>;
    /**
     * 
     * @type {TermConditionDescription}
     * @memberof AccountTermCondition
     */
    termConditionDescription?: TermConditionDescription;
    /**
     * The participants of the term and condtion.
     * @type {Array<Participant>}
     * @memberof AccountTermCondition
     */
    participants?: Array<Participant>;
    /**
     * The unique ID of the term and condition.
     * @type {number}
     * @memberof AccountTermCondition
     */
    referenceNumber?: number;
    /**
     * The action to the term condition.
     * @type {string}
     * @memberof AccountTermCondition
     */
    action?: AccountTermConditionActionEnum;
    /**
     * This column is used to indicate if the trading agreement participant must respond to this term during negotiation.
     * @type {boolean}
     * @memberof AccountTermCondition
     */
    mandatory?: boolean;
    /**
     * This column indicates if the trading agreement participant can change the term.
     * @type {boolean}
     * @memberof AccountTermCondition
     */
    changeable?: boolean;
    /**
     * a number indicating the sequence of the term within a trading agreement. This number must be unique within a trading agreement.
     * @type {number}
     * @memberof AccountTermCondition
     */
    sequenceNumber?: number;
    /**
     * Time that this term and condition was created.
     * @type {string}
     * @memberof AccountTermCondition
     */
    createTime?: string;
    /**
     * Time that this term and condition was last updated.
     * @type {string}
     * @memberof AccountTermCondition
     */
    updateTime?: string;
    /**
     * 
     * @type {PaymentTCAllOfPaymentMethod}
     * @memberof AccountTermCondition
     */
    paymentMethod?: PaymentTCAllOfPaymentMethod;
    /**
     * 
     * @type {PaymentTCAllOfBillToAddress}
     * @memberof AccountTermCondition
     */
    billToAddress?: PaymentTCAllOfBillToAddress;
    /**
     * 
     * @type {PaymentMethodDisplayString}
     * @memberof AccountTermCondition
     */
    paymentMethodDisplayString?: PaymentMethodDisplayString;
    /**
     * The attributes of the payment method term and condition.
     * @type {Array<AttributeDetail>}
     * @memberof AccountTermCondition
     */
    attributeDetails?: Array<AttributeDetail>;
    /**
     * The purchase order number must be unique.
     * @type {string}
     * @memberof AccountTermCondition
     */
    checkUniqueness?: string;
    /**
     * The purchase order number.
     * @type {string}
     * @memberof AccountTermCondition
     */
    ponumber?: string;
    /**
     * The spending limit of the order.
     * @type {string}
     * @memberof AccountTermCondition
     */
    spendingLimit?: string;
    /**
     * 
     * @type {BusinessPolicyRef}
     * @memberof AccountTermCondition
     */
    shippingModePolicyRef?: BusinessPolicyRef;
    /**
     * 
     * @type {BusinessPolicyRef}
     * @memberof AccountTermCondition
     */
    shippingChargePolicyRef?: BusinessPolicyRef;
    /**
     * 
     * @type {AddressReference}
     * @memberof AccountTermCondition
     */
    addressReference?: AddressReference;
    /**
     * The usage of the address.
     * @type {string}
     * @memberof AccountTermCondition
     */
    usage?: AccountTermConditionUsageEnum;
    /**
     * If the billing address is not predefined, customers can whether or not select and address from their personal address book.
     * @type {boolean}
     * @memberof AccountTermCondition
     */
    usePersonal?: boolean;
    /**
     * If the billing address is not predefined, customers can whether or not select and address from their parent organization\'s address book.
     * @type {boolean}
     * @memberof AccountTermCondition
     */
    useParentOrganization?: boolean;
    /**
     * If the billing address is not predefined, customers can whether or not select and address from their business account organization\'s address book.
     * @type {boolean}
     * @memberof AccountTermCondition
     */
    useAccountHolderOrganization?: boolean;
    /**
     * 
     * @type {StoreRef}
     * @memberof AccountTermCondition
     */
    storeRef?: StoreRef;
    /**
     * The name of the payment policy.
     * @type {string}
     * @memberof AccountTermCondition
     */
    policyName?: string;
    /**
     * The singed percentage adjustment.
     * @type {number}
     * @memberof AccountTermCondition
     */
    signedPercentage?: number;
    /**
     * The value of the monetary amount.
     * @type {number}
     * @memberof AccountTermCondition
     */
    value?: number;
    /**
     * The currency of the monetary amount.
     * @type {string}
     * @memberof AccountTermCondition
     */
    currency?: string;
    /**
     * 
     * @type {Owner}
     * @memberof AccountTermCondition
     */
    owner?: Owner;
    /**
     * The unique ID of the catalog group.
     * @type {number}
     * @memberof AccountTermCondition
     */
    catalogGroupReferenceNumber?: number;
    /**
     * The identifier of the catalog group.
     * @type {string}
     * @memberof AccountTermCondition
     */
    groupIdentifier?: string;
    /**
     * The unique ID of the catalog entry.
     * @type {number}
     * @memberof AccountTermCondition
     */
    catalogEntryReferenceNumber?: number;
    /**
     * The part number of the catalog entry.
     * @type {string}
     * @memberof AccountTermCondition
     */
    partNumber?: string;
    /**
     * The unique ID of the catalog.
     * @type {number}
     * @memberof AccountTermCondition
     */
    catalogReferenceNumber?: number;
    /**
     * The name of the catalog.
     * @type {string}
     * @memberof AccountTermCondition
     */
    name?: string;
    /**
     * The types of the extended term and condition.
     * @type {string}
     * @memberof AccountTermCondition
     */
    type?: AccountTermConditionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountTermConditionClassEnum {
    ComIbmCommerceTradingEntitiesTermCondition = 'com.ibm.commerce.trading.entities.TermCondition'
}
/**
    * @export
    * @enum {string}
    */
export enum AccountTermConditionActionEnum {
    New = 'new',
    Update = 'update',
    Delete = 'delete',
    Noaction = 'noaction',
    Ignore = 'ignore'
}
/**
    * @export
    * @enum {string}
    */
export enum AccountTermConditionUsageEnum {
    Shipping = 'shipping',
    Billing = 'billing'
}
/**
    * @export
    * @enum {string}
    */
export enum AccountTermConditionTypeEnum {
    CatalogFilterTc = 'CatalogFilterTC',
    PriceRuleTc = 'PriceRuleTC'
}



