/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { DisplayPriceIBMStoreDisplayPrice } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * DisplayPriceApi - axios parameter creator
 * @export
 */
export const DisplayPriceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets display prices for catalog entries by part numbers and price rule ID.
         * @summary Get by part number and price rule ID
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {Array<string>} partNumber The unique id of catalog entry.
         * @param {string} priceRuleId The unique id of price rule.
         * @param {string} [currency] The currency of the price.
         * @param {string} [quantity] The quantity of the catalog entry.
         * @param {string} [uom] The unit of measurement of the quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayPriceByPartNumbersAndPriceRuleId: async (storeId: string, q: string, partNumber: Array<string>, priceRuleId: string, currency?: string, quantity?: string, uom?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('displayPriceByPartNumbersAndPriceRuleId', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('displayPriceByPartNumbersAndPriceRuleId', 'q', q)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('displayPriceByPartNumbersAndPriceRuleId', 'partNumber', partNumber)
            // verify required parameter 'priceRuleId' is not null or undefined
            assertParamExists('displayPriceByPartNumbersAndPriceRuleId', 'priceRuleId', priceRuleId)
            const localVarPath = `/store/{storeId}/display_price`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (partNumber) {
                localVarQueryParameter['partNumber'] = partNumber.join(COLLECTION_FORMATS.csv);
            }

            if (priceRuleId !== undefined) {
                localVarQueryParameter['priceRuleId'] = priceRuleId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (quantity !== undefined) {
                localVarQueryParameter['quantity'] = quantity;
            }

            if (uom !== undefined) {
                localVarQueryParameter['uom'] = uom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DisplayPriceApi - functional programming interface
 * @export
 */
export const DisplayPriceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DisplayPriceApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets display prices for catalog entries by part numbers and price rule ID.
         * @summary Get by part number and price rule ID
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {Array<string>} partNumber The unique id of catalog entry.
         * @param {string} priceRuleId The unique id of price rule.
         * @param {string} [currency] The currency of the price.
         * @param {string} [quantity] The quantity of the catalog entry.
         * @param {string} [uom] The unit of measurement of the quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displayPriceByPartNumbersAndPriceRuleId(storeId: string, q: string, partNumber: Array<string>, priceRuleId: string, currency?: string, quantity?: string, uom?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayPriceIBMStoreDisplayPrice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displayPriceByPartNumbersAndPriceRuleId(storeId, q, partNumber, priceRuleId, currency, quantity, uom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DisplayPriceApi - factory interface
 * @export
 */
export const DisplayPriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DisplayPriceApiFp(configuration)
    return {
        /**
         * Gets display prices for catalog entries by part numbers and price rule ID.
         * @summary Get by part number and price rule ID
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {Array<string>} partNumber The unique id of catalog entry.
         * @param {string} priceRuleId The unique id of price rule.
         * @param {string} [currency] The currency of the price.
         * @param {string} [quantity] The quantity of the catalog entry.
         * @param {string} [uom] The unit of measurement of the quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayPriceByPartNumbersAndPriceRuleId(storeId: string, q: string, partNumber: Array<string>, priceRuleId: string, currency?: string, quantity?: string, uom?: string, options?: any): AxiosPromise<DisplayPriceIBMStoreDisplayPrice> {
            return localVarFp.displayPriceByPartNumbersAndPriceRuleId(storeId, q, partNumber, priceRuleId, currency, quantity, uom, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DisplayPriceApi - object-oriented interface
 * @export
 * @class DisplayPriceApi
 * @extends {BaseAPI}
 */
export class DisplayPriceApi extends BaseAPI {
    /**
     * Gets display prices for catalog entries by part numbers and price rule ID.
     * @summary Get by part number and price rule ID
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {Array<string>} partNumber The unique id of catalog entry.
     * @param {string} priceRuleId The unique id of price rule.
     * @param {string} [currency] The currency of the price.
     * @param {string} [quantity] The quantity of the catalog entry.
     * @param {string} [uom] The unit of measurement of the quantity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisplayPriceApi
     */
    public displayPriceByPartNumbersAndPriceRuleId(storeId: string, q: string, partNumber: Array<string>, priceRuleId: string, currency?: string, quantity?: string, uom?: string, options?: any) {
        return DisplayPriceApiFp(this.configuration).displayPriceByPartNumbersAndPriceRuleId(storeId, q, partNumber, priceRuleId, currency, quantity, uom, options).then((request) => request(this.axios, this.basePath));
    }
}
