/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerUserContextHandlerPersonalizationIdentifier } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerUserContextHandlerUserContext } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * UserContextApi - axios parameter creator
 * @export
 */
export const UserContextApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the context data by the cookies and tokens sent with the request.
         * @summary Get context data from request
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userContextGetContextData: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('userContextGetContextData', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/usercontext/@self/contextdata`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets or generates the Personalization ID of the current session.
         * @summary Get personalization ID
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userContextGetPersonalizationID: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('userContextGetPersonalizationID', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/usercontext/personalizationId`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserContextApi - functional programming interface
 * @export
 */
export const UserContextApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserContextApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the context data by the cookies and tokens sent with the request.
         * @summary Get context data from request
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userContextGetContextData(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerUserContextHandlerUserContext>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userContextGetContextData(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets or generates the Personalization ID of the current session.
         * @summary Get personalization ID
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userContextGetPersonalizationID(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerUserContextHandlerPersonalizationIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userContextGetPersonalizationID(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserContextApi - factory interface
 * @export
 */
export const UserContextApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserContextApiFp(configuration)
    return {
        /**
         * Gets the context data by the cookies and tokens sent with the request.
         * @summary Get context data from request
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userContextGetContextData(storeId: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerUserContextHandlerUserContext> {
            return localVarFp.userContextGetContextData(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets or generates the Personalization ID of the current session.
         * @summary Get personalization ID
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userContextGetPersonalizationID(storeId: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerUserContextHandlerPersonalizationIdentifier> {
            return localVarFp.userContextGetPersonalizationID(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserContextApi - object-oriented interface
 * @export
 * @class UserContextApi
 * @extends {BaseAPI}
 */
export class UserContextApi extends BaseAPI {
    /**
     * Gets the context data by the cookies and tokens sent with the request.
     * @summary Get context data from request
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContextApi
     */
    public userContextGetContextData(storeId: string, responseFormat?: string, options?: any) {
        return UserContextApiFp(this.configuration).userContextGetContextData(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets or generates the Personalization ID of the current session.
     * @summary Get personalization ID
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContextApi
     */
    public userContextGetPersonalizationID(storeId: string, responseFormat?: string, options?: any) {
        return UserContextApiFp(this.configuration).userContextGetPersonalizationID(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
