/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { CatalogIBMAdminDetails } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CatalogApi - axios parameter creator
 * @export
 */
export const CatalogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all the catalogs of a store by store id.
         * @param {string} storeId The store identifier.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter will be used. If \&quot;locale\&quot; isn\&#39;t specified, then the store default language shall be used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data to be returned by a query.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByStoreId: async (storeId: string, langId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findByStoreId', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/catalog`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogApi - functional programming interface
 * @export
 */
export const CatalogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all the catalogs of a store by store id.
         * @param {string} storeId The store identifier.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter will be used. If \&quot;locale\&quot; isn\&#39;t specified, then the store default language shall be used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data to be returned by a query.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByStoreId(storeId: string, langId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogIBMAdminDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByStoreId(storeId, langId, profileName, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CatalogApi - factory interface
 * @export
 */
export const CatalogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all the catalogs of a store by store id.
         * @param {string} storeId The store identifier.
         * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter will be used. If \&quot;locale\&quot; isn\&#39;t specified, then the store default language shall be used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data to be returned by a query.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByStoreId(storeId: string, langId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<CatalogIBMAdminDetails> {
            return localVarFp.findByStoreId(storeId, langId, profileName, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogApi - object-oriented interface
 * @export
 * @class CatalogApi
 * @extends {BaseAPI}
 */
export class CatalogApi extends BaseAPI {
    /**
     * 
     * @summary Get all the catalogs of a store by store id.
     * @param {string} storeId The store identifier.
     * @param {string} [langId] Language identifier. If not specified, the \&quot;locale\&quot; parameter will be used. If \&quot;locale\&quot; isn\&#39;t specified, then the store default language shall be used.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data to be returned by a query.
     * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogApi
     */
    public findByStoreId(storeId: string, langId?: string, profileName?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return CatalogApiFp(this.configuration).findByStoreId(storeId, langId, profileName, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}
