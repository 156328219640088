/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { AnyAction, PayloadAction, createReducer } from "@reduxjs/toolkit";
import { NOT_FOUND } from "http-status-codes";
//Custom libraries
import { HYPHEN, ORDER_ID } from "../../constants/common";
import { INVENTORY_STATUS, PAYMENT } from "../../constants/order";
//Foundation libraries
import { ACCOUNT } from "../../_foundation/constants/common";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
//Redux
import parseISO from "date-fns/parseISO";
import moment from "moment";
import { PRODUCT_USERDATA } from "solteq/src/constants/common";
import { CartItem } from "solteq/src/types/order/order.types";
import { isInvalidDateForSelection } from "solteq/src/utils/DateUtils";
import { v4 as uuid } from "uuid";
import * as ACTIONS from "../action-types/order";
import {
  FETCH_ALLOWABLE_PAYMETHODS_S_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION,
  FETCH_OPEN_ORDER_LINES_ERROR_ACTION,
  FETCH_OPEN_ORDER_LINES_SUCCESS_ACTION,
  FETCH_ORDERS_ERROR_ACTION,
  FETCH_ORDERS_SUCCESS_ACTION,
  FETCH_ORDER_LINES_CLEAR_ACTION,
  GET_PAYTRAIL_PAYMETHODS_SUCCESS_ACTION,
  ORDER_METHOD_RESET_ACTION,
  ORDER_METHOD_SET_PICKUP_ACTION,
  PAYTRAIL_METHOD_SELECTED_ACTION,
  SET_PICKUP_PERSON_ACTION,
} from "../actions/order";
import { LOGOUT_SUCCESS_ACTION } from "../actions/user";
import { OpenOrderDetail } from "./../../solteq/src/types/order/order.types";
import initStates from "./initStates";
import { OrderReducerState } from "./reducerStateInterface";

/**
 * Order reducer
 * handles states used by order related components
 * @param state State object managed by order reducer
 * @param action The dispatched action
 */

const orderReducer = createReducer(initStates.order, (builder) => {
  builder.addCase(ACTIONS.CART_FETCHING_REQUESTED, (state: OrderReducerState, action: AnyAction) => {
    state.isFetching = true;
  });
  builder.addCase(ACTIONS.CART_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response;
    if (response) {
      const { orderItem: orderItems, ...cart } = response;
      const newCatentries = action.catentries;
      const checkInventory = action.checkInventory;
      const purchasePrices: Map<string, number> = action.purchasePrices;

      state.cart = cart;

      let count = 0;
      if (orderItems) {
        count = orderItems.reduce((c: number, item: any) => +item.quantity + c, 0);
      }
      state.numItems = count;

      let newOrderItems: CartItem[] = [];
      let disableRecurringOrder = false;
      let disableCheckout = false;
      if (orderItems && orderItems.length > 0) {
        let catentries = state.catentries;

        if (newCatentries !== undefined) {
          catentries = newCatentries;
          state.catentries = newCatentries;
        }

        newOrderItems = [];
        orderItems.forEach((item: any, index: number) => {
          if (checkInventory) {
            if (
              item.orderItemInventoryStatus !== INVENTORY_STATUS.available &&
              item.orderItemInventoryStatus !== INVENTORY_STATUS.allocated
            ) {
              disableCheckout = true;
            }
          }
          let obj: CartItem = {
            ...item,
          };

          // Use requestedShipDate only if it's defined and not in the past.
          // Otherwise, use the day after tomorrow as a default.
          if (obj.requestedShipDate) {
            const parsedDate = parseISO(obj.requestedShipDate);
            if (parsedDate && isInvalidDateForSelection(parsedDate, true)) {
              obj.requestedShipDate = moment(new Date()).add(1, "days").toISOString();
            }
          }

          const catentryId = item.productId;
          if (catentries != null) {
            const catentry = catentries[catentryId];

            if (catentry.UserData?.length > 0) {
              catentry.UserData.forEach((f) => {
                switch (true) {
                  case f[PRODUCT_USERDATA.CONTRACTPRICEWITHOUTTAX]:
                    obj.contractPriceWithOutTax = f[PRODUCT_USERDATA.CONTRACTPRICEWITHOUTTAX];
                    break;
                  case f[PRODUCT_USERDATA.OFFERPRICE]:
                    obj.offerPrice = f[PRODUCT_USERDATA.OFFERPRICE];
                    break;
                  case f[PRODUCT_USERDATA.OFFERPRICEWITHOUTTAX]:
                    obj.offerPriceWithOutTax = f[PRODUCT_USERDATA.OFFERPRICEWITHOUTTAX];
                    break;
                  case f[PRODUCT_USERDATA.VATRATE]:
                    obj.vatRate = f[PRODUCT_USERDATA.VATRATE];
                    break;
                }
              });
            }
            if (catentry !== undefined) {
              const { seller, sellerId } = catentry;
              if (catentry.name !== undefined) {
                obj.name = catentry.name;
              }
              if (catentry.thumbnail !== undefined) {
                obj.thumbnail = catentry.thumbnail;
              }
              if (catentry.attributes !== undefined) {
                obj.attributes = catentry.attributes;
              }
              if (catentry.seo !== undefined) {
                obj.seo = catentry.seo;
              }
              if (catentry.disallowRecurringOrder !== undefined) {
                obj.disallowRecurringOrder = catentry.disallowRecurringOrder;
                if (catentry.disallowRecurringOrder === "1") {
                  disableRecurringOrder = true;
                }
              }
              if (catentry.parentCatalogGroupID !== undefined) {
                obj.parentCatalogGroupID = catentry.parentCatalogGroupID;
              }
              if (purchasePrices.has(catentryId)) {
                obj.purchasePrice = purchasePrices.get(catentryId);
              }
              if (catentry.listPrice) {
                obj.listPrice = catentry.listPrice;
              }
              Object.assign(obj, { seller, sellerId });
            }
          }
          newOrderItems.push(obj);
        });

        state.isCheckoutDisabled = disableCheckout;
        state.isRecurringOrderDisabled = disableRecurringOrder;
      }
      state.orderItems = newOrderItems;
    }
    if (state.isRecurringOrderDisabled && state.cart && state.cart.orderId) {
      if (localStorageUtil.get(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId)) {
        const recurringOrderInfo: any[] = [false, "0", null];
        localStorageUtil.set(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId, recurringOrderInfo);
      }
    }
    state.isFetching = false;
    state.itemsInProgress = false;
  });

  builder.addCase(ACTIONS.CART_GET_ERROR, (state: OrderReducerState, action: AnyAction) => {
    if (
      action.error &&
      action.error.response &&
      action.error.response.status &&
      action.error.response.status === NOT_FOUND
    ) {
      state.cart = null;
      state.numItems = 0;
      state.orderItems = [];
    }
    state.isCheckoutDisabled = true;
    state.isFetching = false;
    state.itemsInProgress = false;
  });
  builder.addCase(ACTIONS.UPDATE_SHIPPING_INFO, (state: OrderReducerState, action: AnyAction) => {
    state.shipInfosProcessing = true;
  });
  builder.addCase(ACTIONS.SHIPMODE_UPDATE_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    state.shipInfosProcessing = false;
  });
  builder.addCase(ACTIONS.SHIPINFO_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    state.shipInfos = action.response;
  });
  builder.addCase(ACTIONS.SHIPMODES_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response;
    if (response && response.usableShippingMode) {
      state.shipModes = response.usableShippingMode;
    }
  });
  builder.addCase(ACTIONS.PAYMETHODS_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response;
    if (response && response.usablePaymentInformation) {
      let paymentMethodsList: any[] = [];

      for (let payment of response.usablePaymentInformation) {
        if (
          payment.paymentMethodName === PAYMENT.paymentMethodName.BillMeLater ||
          payment.paymentMethodName === PAYMENT.paymentMethodName.PayLater ||
          payment.paymentMethodName === PAYMENT.paymentMethodName.Paytrail
        ) {
          paymentMethodsList.push(payment);
        }
      }

      state.payMethods = paymentMethodsList;
    }
  });
  builder.addCase(LOGOUT_SUCCESS_ACTION, resetCart);
  builder.addCase(ACTIONS.CART_RESET_REQUESTED, resetCart);
  builder.addCase(ACTIONS.ITEMS_REMOVE_REQUESTED, setItemsInProgress);
  builder.addCase(ACTIONS.ITEMS_REMOVE_ERROR, setItemsInProgress);
  builder.addCase(ACTIONS.ITEMS_COPY_REQUESTED, setItemsInProgress);
  builder.addCase(ACTIONS.ITEMS_COPY_ERROR, setItemsInProgress);
  builder.addCase(ACTIONS.BULKUPDATE_SHIPPING_DATES_REQUESTED, setItemsInProgress);
  builder.addCase(ACTIONS.UPDATE_ITEMS_SHIPPING_ADDRESS_REQUESTED, setItemsInProgress);
  builder.addCase(ACTIONS.UPDATE_ITEMS_PRICE_REQUESTED, setItemsInProgress);
  builder.addCase(ACTIONS.ITEM_ADD_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    // If item add called with shipping address change, set order items to be moved temporarily to state
    if (action.payload && action.payload.addressId && action.response && action.response.orderItem) {
      state.orderItemIdsToMove = action.response.orderItem.map((x) => x.orderItemId);
    }
  });
  builder.addCase(FETCH_ORDERS_SUCCESS_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.listOfOrders = action.response || [];
  });
  builder.addCase(FETCH_ORDERS_ERROR_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.listOfOrders = [];
  });
  builder.addCase(FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.allowableShipModes = action.payload.modes ?? [];
  });
  builder.addCase(ACTIONS.SET_ACTIVE_INPROGRESS_ORDER, (state: OrderReducerState, action: AnyAction) => {
    state.activeInprogressOrder = action.payload.order;
  });
  builder.addCase(ACTIONS.RESET_ACTIVE_INPROGRESS_ORDER, (state: OrderReducerState, action: AnyAction) => {
    state.activeInprogressOrder = null;
  });
  builder.addCase(ACTIONS.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    state.cart = action.payload.orderDetails;
    const checkInventory = action.payload.checkInventory;
    const newCatentries = action.payload.catentries;
    const orderItems = action.payload.orderDetails.orderItem;
    let count = 0;
    if (orderItems) {
      count = orderItems.reduce((c: number, item: any) => +item.quantity + c, 0);
    }
    state.numItems = count;

    let newOrderItems: any[] = [];
    let disableRecurringOrder = false;
    let disableCheckout = false;
    if (orderItems && orderItems.length > 0) {
      let catentries = state.catentries;

      if (newCatentries !== undefined) {
        catentries = newCatentries;
        state.catentries = newCatentries;
      }

      newOrderItems = [];
      orderItems.forEach((item: any, index: number) => {
        if (checkInventory) {
          if (
            item.orderItemInventoryStatus !== INVENTORY_STATUS.available &&
            item.orderItemInventoryStatus !== INVENTORY_STATUS.allocated
          ) {
            disableCheckout = true;
          }
        }
        let obj = {
          ...item,
        };
        const catentryId = item.productId;
        if (catentries != null) {
          const catentry = catentries[catentryId];
          if (catentry !== undefined) {
            const { seller, sellerId } = catentry;

            if (catentry.name !== undefined) {
              obj["name"] = catentry.name;
            }
            if (catentry.thumbnail !== undefined) {
              obj["thumbnail"] = catentry.thumbnail;
            }
            if (catentry.attributes !== undefined) {
              obj["attributes"] = catentry.attributes;
            }
            if (catentry.seo !== undefined) {
              obj["seo"] = catentry.seo;
            }
            if (catentry.disallowRecurringOrder !== undefined) {
              obj["disallowRecurringOrder"] = catentry.disallowRecurringOrder;
              if (catentry.disallowRecurringOrder === "1") {
                disableRecurringOrder = true;
              }
            }
            if (catentry.parentCatalogGroupID !== undefined) {
              obj["parentCatalogGroupID"] = catentry.parentCatalogGroupID;
            }

            Object.assign(obj, { seller, sellerId });
          }
        }
        newOrderItems.push(obj);
      });

      state.isCheckoutDisabled = disableCheckout;
      state.isRecurringOrderDisabled = disableRecurringOrder;
    }
    state.orderItems = newOrderItems;
    state.isFetching = false;
  });
  builder.addCase(ACTIONS.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR, (state: OrderReducerState, action: AnyAction) => {
    state.activeInprogressOrder = null;
    if (
      action.error &&
      action.error.response &&
      action.error.response.status &&
      action.error.response.status === NOT_FOUND
    ) {
      state.cart = null;
      state.numItems = 0;
      state.orderItems = [];
    }
    state.isCheckoutDisabled = true;
    state.isFetching = false;
  });

  builder.addCase(FETCH_ALLOWABLE_PAYMETHODS_S_ACTION, (state: OrderReducerState, action: AnyAction) => {
    const m = action.payload.methods ?? [];
    state.allowablePaymethods = m.filter(({ policyName: p }) => PAYMENT.policies[p]);
  });

  builder.addCase(FETCH_OPEN_ORDER_LINES_SUCCESS_ACTION, (state: OrderReducerState, action: PayloadAction<any>) => {
    // Let's map the open order lines to more simple form...
    const orderLines: OpenOrderDetail[] =
      action.payload?.orderLines.map((o) => {
        let openOrderDetail: OpenOrderDetail = {
          id: uuid(),
          sapId: o.Order?.length > 0 && o.Order[0].OrderNo ? o.Order[0].OrderNo : undefined,
          sapOrderLineNo: o.Extn?.SapOrderLineNo?.length > 0 ? o.Extn?.SapOrderLineNo : o.PrimeLineNo,
          orderReference: resolveOrderReference(o.Order),
          itemId: o.Item?.ItemID,
          itemShortDesc: o.Item?.ItemShortDesc,
          remainingQty: o.RemainingQty ? Number(o.RemainingQty) : 0,
          unitOfMeasure: o.Item?.UnitOfMeasure,
          totalPrice: o.LinePriceInfo?.ListPriceTotal,
          currency: o.Order?.length > 0 && o.Order[0].PriceInfo ? o.Order[0].PriceInfo.Currency : undefined,
          orderDate:
            o.Order?.length > 0 && o.Order[0].OrderDate?.length > 0 ? new Date(o.Order[0].OrderDate) : undefined,
          deliveryDate: o.ReqShipDate?.length > 0 ? new Date(o.ReqShipDate) : undefined,
          availabilities: [],
        };

        if (o.AdditionalLinePrices?.AdditionalLinePrice?.length > 0) {
          const salesPrice = o.AdditionalLinePrices?.AdditionalLinePrice.find(
            (p) => p.PriceTypeName === "SalesPriceWithoutVAT"
          );
          if (salesPrice && salesPrice.Price && salesPrice.Price.length > 0) {
            openOrderDetail.salesPriceWithoutVAT = Number(salesPrice.Price);
          }
        }

        if (o.availabilities) {
          openOrderDetail.availabilities = [
            {
              availabileQuantity: o.availabilities.online,
              inventoryStatus: o.availabilities.online > 0,
              availabilitySinceDates:
                o.availabilities.avails?.length > 0
                  ? o.availabilities.avails.map((a) => {
                      return [a.date, a.saldo];
                    })
                  : [],
            },
          ];
        }

        return openOrderDetail;
      }) ?? [];
    state.openOrderLines = orderLines;
  });

  builder.addCase(FETCH_OPEN_ORDER_LINES_ERROR_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.openOrderLines = [];
  });
  builder.addCase(FETCH_ORDER_LINES_CLEAR_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.openOrderLines = undefined;
  });
  builder.addCase(ORDER_METHOD_SET_PICKUP_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.orderMethodIsPickup = true;
  });
  builder.addCase(ORDER_METHOD_RESET_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.orderMethodIsPickup = false;
  });
  builder.addCase(SET_PICKUP_PERSON_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.pickupPerson = action.payload;
  });
  builder.addCase(GET_PAYTRAIL_PAYMETHODS_SUCCESS_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.paytrailPaymentMethods = action.payload.methods;
  });
  builder.addCase(PAYTRAIL_METHOD_SELECTED_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.isCheckoutDisabled = true;
  });
  builder.addCase(ACTIONS.PAYTRAIL_METHOD_SELECTED_DONE, (state: OrderReducerState, action: AnyAction) => {
    state.isCheckoutDisabled = false;
  });
});

function resolveOrderReference(orderArray: any): string | undefined {
  if (orderArray?.length > 0) {
    const order = orderArray[0];
    if (order.PaymentMethods?.PaymentMethod?.length > 0) {
      return order.PaymentMethods.PaymentMethod[0].PaymentReference3;
    } else if (order.CustomerPONo?.length > 0) {
      return order.CustomerPONo;
    }
  }
  return undefined;
}

function setItemsInProgress(state: OrderReducerState, action: AnyAction) {
  state.itemsInProgress = !state.itemsInProgress;
}

function resetCart(state: OrderReducerState, action: AnyAction) {
  state.cart = null;
  resetCartInfo(state, action);
}

function resetCartInfo(state: OrderReducerState, action: AnyAction) {
  state.numItems = 0;
  state.orderItems = [];
  state.catentries = null;
  state.isCheckoutDisabled = false;
  state.shipInfos = null;
  state.shipModes = [];
  state.payMethods = [];
  state.isRecurringOrderDisabled = false;
  state.isFetching = false;
  state.itemsInProgress = false;
  state.orderItemIdsToMove = [];
  state.activeInprogressOrder = null;
  state.listOfOrders = [];
  state.allowableShipModes = [];
  state.allowablePaymethods = [];
}

export default orderReducer;
