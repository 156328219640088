/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceOrderBeansRequisitionListDataBeanIBMStoreSummary } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Finds the contracts the current user is eligible to.
         * @param {string} storeId The store identifier.
         * @param {string} contractId The contract identifier.
         * @param {string} [currency] The currency code to use.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data to be returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdContractContractIdGet: async (storeId: string, contractId: string, currency?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdContractContractIdGet', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('storeStoreIdContractContractIdGet', 'contractId', contractId)
            const localVarPath = `/store/{storeId}/contract/{contractId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finds the contracts the current user is eligible to.
         * @param {string} storeId The store identifier.
         * @param {string} [q] The query name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdContractGet: async (storeId: string, q?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdContractGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/contract`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Finds the contracts the current user is eligible to.
         * @param {string} storeId The store identifier.
         * @param {string} contractId The contract identifier.
         * @param {string} [currency] The currency code to use.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data to be returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdContractContractIdGet(storeId: string, contractId: string, currency?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdContractContractIdGet(storeId, contractId, currency, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finds the contracts the current user is eligible to.
         * @param {string} storeId The store identifier.
         * @param {string} [q] The query name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdContractGet(storeId: string, q?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceOrderBeansRequisitionListDataBeanIBMStoreSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdContractGet(storeId, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * 
         * @summary Finds the contracts the current user is eligible to.
         * @param {string} storeId The store identifier.
         * @param {string} contractId The contract identifier.
         * @param {string} [currency] The currency code to use.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data to be returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdContractContractIdGet(storeId: string, contractId: string, currency?: string, profileName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdContractContractIdGet(storeId, contractId, currency, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finds the contracts the current user is eligible to.
         * @param {string} storeId The store identifier.
         * @param {string} [q] The query name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdContractGet(storeId: string, q?: string, options?: any): AxiosPromise<ComIbmCommerceOrderBeansRequisitionListDataBeanIBMStoreSummary> {
            return localVarFp.storeStoreIdContractGet(storeId, q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 
     * @summary Finds the contracts the current user is eligible to.
     * @param {string} storeId The store identifier.
     * @param {string} contractId The contract identifier.
     * @param {string} [currency] The currency code to use.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data to be returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public storeStoreIdContractContractIdGet(storeId: string, contractId: string, currency?: string, profileName?: string, options?: any) {
        return ContractApiFp(this.configuration).storeStoreIdContractContractIdGet(storeId, contractId, currency, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finds the contracts the current user is eligible to.
     * @param {string} storeId The store identifier.
     * @param {string} [q] The query name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public storeStoreIdContractGet(storeId: string, q?: string, options?: any) {
        return ContractApiFp(this.configuration).storeStoreIdContractGet(storeId, q, options).then((request) => request(this.axios, this.basePath));
    }
}
