/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AttributeDetail } from './attribute-detail';
import { PaymentMethodDisplayString } from './payment-method-display-string';
import { PaymentTCAllOfBillToAddress } from './payment-tcall-of-bill-to-address';
import { PaymentTCAllOfPaymentMethod } from './payment-tcall-of-payment-method';

/**
 * The payment method term and condition. <br>This optional term specifies the payment methods that will be accepted for orders made under the contract. The payment method can be as general as a payment type, such as a credit card type, or as specific as a credit card number to be used for payment. If no payment method term is specified in a contract, payment in all methods accepted by the store will be accepted for orders made under the contract.
 * @export
 * @interface PaymentTCAllOf
 */
export interface PaymentTCAllOf {
    /**
     * The full entity class name of the term and condition.
     * @type {string}
     * @memberof PaymentTCAllOf
     */
    _class?: PaymentTCAllOfClassEnum;
    /**
     * 
     * @type {PaymentTCAllOfPaymentMethod}
     * @memberof PaymentTCAllOf
     */
    paymentMethod?: PaymentTCAllOfPaymentMethod;
    /**
     * 
     * @type {PaymentTCAllOfBillToAddress}
     * @memberof PaymentTCAllOf
     */
    billToAddress?: PaymentTCAllOfBillToAddress;
    /**
     * 
     * @type {PaymentMethodDisplayString}
     * @memberof PaymentTCAllOf
     */
    paymentMethodDisplayString?: PaymentMethodDisplayString;
    /**
     * The attributes of the payment method term and condition.
     * @type {Array<AttributeDetail>}
     * @memberof PaymentTCAllOf
     */
    attributeDetails?: Array<AttributeDetail>;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentTCAllOfClassEnum {
    ComIbmCommerceTradingEntitiesPaymentTc = 'com.ibm.commerce.trading.entities.PaymentTC'
}



