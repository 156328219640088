/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BusinessPolicyRef } from './business-policy-ref';

/**
 * \"The shipping charge term and condition. <br>This term defines who pays for shipping orders. You can adjust the shipping charge using the Accounts or Contracts notebook in the WebSphere Commerce Accelerator. Shipping charge adjustments can be a discount or increase in charge, and can be a fixed price or percentage adjustment.\"
 * @export
 * @interface ShippingTCShippingChargeAllOf
 */
export interface ShippingTCShippingChargeAllOf {
    /**
     * The full entity class name of the term and condition.
     * @type {string}
     * @memberof ShippingTCShippingChargeAllOf
     */
    _class?: ShippingTCShippingChargeAllOfClassEnum;
    /**
     * 
     * @type {BusinessPolicyRef}
     * @memberof ShippingTCShippingChargeAllOf
     */
    shippingChargePolicyRef?: BusinessPolicyRef;
}

/**
    * @export
    * @enum {string}
    */
export enum ShippingTCShippingChargeAllOfClassEnum {
    ComIbmCommerceTradingEntitiesShippingTcShippingCharge = 'com.ibm.commerce.trading.entities.ShippingTCShippingCharge'
}



