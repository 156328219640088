/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The extended term and condition.
 * @export
 * @interface ExtendedTCAllOf
 */
export interface ExtendedTCAllOf {
    /**
     * The full entity class name of the extended term and condition.
     * @type {string}
     * @memberof ExtendedTCAllOf
     */
    _class?: ExtendedTCAllOfClassEnum;
    /**
     * The types of the extended term and condition.
     * @type {string}
     * @memberof ExtendedTCAllOf
     */
    type?: ExtendedTCAllOfTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ExtendedTCAllOfClassEnum {
    ComIbmCommerceTradingEntitiesTermCondition = 'com.ibm.commerce.trading.entities.TermCondition'
}
/**
    * @export
    * @enum {string}
    */
export enum ExtendedTCAllOfTypeEnum {
    CatalogFilterTc = 'CatalogFilterTC',
    PriceRuleTc = 'PriceRuleTC'
}



