/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MonetaryAmount } from './monetary-amount';

/**
 * The order approval term and condition. <br>This term specifies if orders must be approved by the customer organization before filling the orders. You can specify an optional amount, that includes taxes and shipping, that allows orders with a value below the amount to be filled without approval from the customer organization. If an order total is over this amount, approval is required. If a buyer is placing an order with order items under multiple contracts and one item in the order has a contract specifying this term, the entire order is subject to the order approval term that applies to the item.
 * @export
 * @interface OrderApprovalTCAllOf
 */
export interface OrderApprovalTCAllOf {
    /**
     * The full entity class name of the term and condition.
     * @type {string}
     * @memberof OrderApprovalTCAllOf
     */
    _class?: OrderApprovalTCAllOfClassEnum;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof OrderApprovalTCAllOf
     */
    monetaryAmount?: MonetaryAmount;
}

/**
    * @export
    * @enum {string}
    */
export enum OrderApprovalTCAllOfClassEnum {
    ComIbmCommerceTradingEntitiesOrderApprovalTc = 'com.ibm.commerce.trading.entities.OrderApprovalTC'
}



