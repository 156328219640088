/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ComIbmCommerceFoundationCommonDatatypesOrganizationIdentifierType } from './com-ibm-commerce-foundation-common-datatypes-organization-identifier-type';
import { ComIbmCommerceFoundationCommonDatatypesPersonIdentifierType } from './com-ibm-commerce-foundation-common-datatypes-person-identifier-type';
import { ComIbmCommerceMemberFacadeDatatypesIncomeAmountType } from './com-ibm-commerce-member-facade-datatypes-income-amount-type';
import { PersonPersonAttributes } from './person-person-attributes';
import { PersonPersonContact } from './person-person-contact';
import { PersonPersonContextAttribute } from './person-person-context-attribute';
import { PersonPersonReceiveEmailPreference } from './person-person-receive-email-preference';
import { PersonPersonReceiveSMSPreference } from './person-person-receive-smspreference';
import { PersonPersonUserDataField } from './person-person-user-data-field';

/**
 * 
 * @export
 * @interface PersonPerson
 */
export interface PersonPerson {
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    accountStatus?: PersonPersonAccountStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    addressId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonPerson
     */
    addressLine?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    addressType?: string;
    /**
     * 
     * @type {Array<PersonPersonAttributes>}
     * @memberof PersonPerson
     */
    attributes?: Array<PersonPersonAttributes>;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    bestCallingTime?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    businessTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    challengeQuestion: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    companyName?: string;
    /**
     * 
     * @type {Array<PersonPersonContact>}
     * @memberof PersonPerson
     */
    contact?: Array<PersonPersonContact>;
    /**
     * 
     * @type {Array<PersonPersonContextAttribute>}
     * @memberof PersonPerson
     */
    contextAttribute?: Array<PersonPersonContextAttribute>;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    departmentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    distinguishedName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    email1: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    email2: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    employeeID?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    employeeType?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    fax1: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    fax2: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    gender?: PersonPersonGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    geographicalShippingCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    geographicalTaxCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    hobbies?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    householdSize?: string;
    /**
     * 
     * @type {ComIbmCommerceMemberFacadeDatatypesIncomeAmountType}
     * @memberof PersonPerson
     */
    income?: ComIbmCommerceMemberFacadeDatatypesIncomeAmountType;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    internalOfficeAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    lastUpdate?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    logonId: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    manager?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    maritalStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    mobilePhone1: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    mobilePhone1Country: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    nickName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    numberOfChildren?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    organizationDistinguishedName?: string;
    /**
     * 
     * @type {ComIbmCommerceFoundationCommonDatatypesOrganizationIdentifierType}
     * @memberof PersonPerson
     */
    organizationIdentifier?: ComIbmCommerceFoundationCommonDatatypesOrganizationIdentifierType;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    organizationName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    organizationUnitName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    orgizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    passwordExpired?: string;
    /**
     * 
     * @type {ComIbmCommerceFoundationCommonDatatypesPersonIdentifierType}
     * @memberof PersonPerson
     */
    personIdentifier?: ComIbmCommerceFoundationCommonDatatypesPersonIdentifierType;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    personTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    phone1: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    phone1Publish?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    phone1Type?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    phone2: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    phone2Publish?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    phone2Type?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    photoURI?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    preferredCommunication?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    preferredCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    preferredDelivery?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    primary?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    profileType?: PersonPersonProfileTypeEnum;
    /**
     * 
     * @type {Array<PersonPersonReceiveEmailPreference>}
     * @memberof PersonPerson
     */
    receiveEmailPreference?: Array<PersonPersonReceiveEmailPreference>;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    receiveSMSNotification?: string;
    /**
     * 
     * @type {Array<PersonPersonReceiveSMSPreference>}
     * @memberof PersonPerson
     */
    receiveSMSPreference?: Array<PersonPersonReceiveSMSPreference>;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    registrationApprovalStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    registrationDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    registrationStatus?: PersonPersonRegistrationStatusEnum;
    /**
     * The name of the registrant\'s secretary or administrative assistant.
     * @type {string}
     * @memberof PersonPerson
     */
    secretary?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    state?: string;
    /**
     * 
     * @type {Array<PersonPersonUserDataField>}
     * @memberof PersonPerson
     */
    userDataField?: Array<PersonPersonUserDataField>;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPerson
     */
    zipCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PersonPersonAccountStatusEnum {
    Enabled = 'Enabled',
    Disabled = 'Disabled'
}
/**
    * @export
    * @enum {string}
    */
export enum PersonPersonGenderEnum {
    Male = 'Male',
    Female = 'Female',
    Unspecified = 'Unspecified'
}
/**
    * @export
    * @enum {string}
    */
export enum PersonPersonProfileTypeEnum {
    C = 'C',
    B = 'B'
}
/**
    * @export
    * @enum {string}
    */
export enum PersonPersonRegistrationStatusEnum {
    Guest = 'Guest',
    RegisteredPerson = 'RegisteredPerson'
}



