/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestMarketingHandlerSegmentHandlerSegmentMemberCheck } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { SegmentSegments } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * SegmentApi - axios parameter creator
 * @export
 */
export const SegmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Checks if the customer identified by userId or personalizationId is part of given customer segment.
         * @summary Check segment membership
         * @param {string} storeId The store identifier.
         * @param {string} segmentId Segment identifier.
         * @param {number} [userId] User unique identifier.
         * @param {string} [personalizationId] The user\&#39;s personalization id. Note : this parameter is ignored if the userId parameter is specified.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIsInSegmentByUserId: async (storeId: string, segmentId: string, userId?: number, personalizationId?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkIsInSegmentByUserId', 'storeId', storeId)
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('checkIsInSegmentByUserId', 'segmentId', segmentId)
            const localVarPath = `/store/{storeId}/segment/{segmentId}/isMember`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"segmentId"}}`, String(segmentId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (personalizationId !== undefined) {
                localVarQueryParameter['personalizationId'] = personalizationId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets customer segment by segmentId.
         * @summary Get segment by the segment ID
         * @param {string} storeId The store identifier.
         * @param {string} segmentId The segment identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSegmentById: async (storeId: string, segmentId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getCustomerSegmentById', 'storeId', storeId)
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('getCustomerSegmentById', 'segmentId', segmentId)
            const localVarPath = `/store/{storeId}/segment/{segmentId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"segmentId"}}`, String(segmentId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all customer segments by a specified storeId or get segment by userId or personalizationId or byName.
         * @summary Get segments by user or store ID
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {number} [qUserId] The user identifier. Required if the query is set to byUserId.
         * @param {string} [qPersonalizationId] The user\&#39;s personalization identifier. Required if the query is set to byPersonalizationId.
         * @param {string} [qName] The user\&#39;s name. Required if the query is set to byName.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSegments: async (storeId: string, q: string, qUserId?: number, qPersonalizationId?: string, qName?: string, pageNumber?: number, pageSize?: number, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getCustomerSegments', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getCustomerSegments', 'q', q)
            const localVarPath = `/store/{storeId}/segment`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (qUserId !== undefined) {
                localVarQueryParameter['qUserId'] = qUserId;
            }

            if (qPersonalizationId !== undefined) {
                localVarQueryParameter['qPersonalizationId'] = qPersonalizationId;
            }

            if (qName !== undefined) {
                localVarQueryParameter['qName'] = qName;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SegmentApi - functional programming interface
 * @export
 */
export const SegmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SegmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Checks if the customer identified by userId or personalizationId is part of given customer segment.
         * @summary Check segment membership
         * @param {string} storeId The store identifier.
         * @param {string} segmentId Segment identifier.
         * @param {number} [userId] User unique identifier.
         * @param {string} [personalizationId] The user\&#39;s personalization id. Note : this parameter is ignored if the userId parameter is specified.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkIsInSegmentByUserId(storeId: string, segmentId: string, userId?: number, personalizationId?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMarketingHandlerSegmentHandlerSegmentMemberCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkIsInSegmentByUserId(storeId, segmentId, userId, personalizationId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets customer segment by segmentId.
         * @summary Get segment by the segment ID
         * @param {string} storeId The store identifier.
         * @param {string} segmentId The segment identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSegmentById(storeId: string, segmentId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSegmentById(storeId, segmentId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all customer segments by a specified storeId or get segment by userId or personalizationId or byName.
         * @summary Get segments by user or store ID
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {number} [qUserId] The user identifier. Required if the query is set to byUserId.
         * @param {string} [qPersonalizationId] The user\&#39;s personalization identifier. Required if the query is set to byPersonalizationId.
         * @param {string} [qName] The user\&#39;s name. Required if the query is set to byName.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSegments(storeId: string, q: string, qUserId?: number, qPersonalizationId?: string, qName?: string, pageNumber?: number, pageSize?: number, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSegments(storeId, q, qUserId, qPersonalizationId, qName, pageNumber, pageSize, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SegmentApi - factory interface
 * @export
 */
export const SegmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SegmentApiFp(configuration)
    return {
        /**
         * Checks if the customer identified by userId or personalizationId is part of given customer segment.
         * @summary Check segment membership
         * @param {string} storeId The store identifier.
         * @param {string} segmentId Segment identifier.
         * @param {number} [userId] User unique identifier.
         * @param {string} [personalizationId] The user\&#39;s personalization id. Note : this parameter is ignored if the userId parameter is specified.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIsInSegmentByUserId(storeId: string, segmentId: string, userId?: number, personalizationId?: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestMarketingHandlerSegmentHandlerSegmentMemberCheck> {
            return localVarFp.checkIsInSegmentByUserId(storeId, segmentId, userId, personalizationId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets customer segment by segmentId.
         * @summary Get segment by the segment ID
         * @param {string} storeId The store identifier.
         * @param {string} segmentId The segment identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSegmentById(storeId: string, segmentId: string, responseFormat?: string, options?: any): AxiosPromise<SegmentSegments> {
            return localVarFp.getCustomerSegmentById(storeId, segmentId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all customer segments by a specified storeId or get segment by userId or personalizationId or byName.
         * @summary Get segments by user or store ID
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {number} [qUserId] The user identifier. Required if the query is set to byUserId.
         * @param {string} [qPersonalizationId] The user\&#39;s personalization identifier. Required if the query is set to byPersonalizationId.
         * @param {string} [qName] The user\&#39;s name. Required if the query is set to byName.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSegments(storeId: string, q: string, qUserId?: number, qPersonalizationId?: string, qName?: string, pageNumber?: number, pageSize?: number, responseFormat?: string, options?: any): AxiosPromise<SegmentSegments> {
            return localVarFp.getCustomerSegments(storeId, q, qUserId, qPersonalizationId, qName, pageNumber, pageSize, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SegmentApi - object-oriented interface
 * @export
 * @class SegmentApi
 * @extends {BaseAPI}
 */
export class SegmentApi extends BaseAPI {
    /**
     * Checks if the customer identified by userId or personalizationId is part of given customer segment.
     * @summary Check segment membership
     * @param {string} storeId The store identifier.
     * @param {string} segmentId Segment identifier.
     * @param {number} [userId] User unique identifier.
     * @param {string} [personalizationId] The user\&#39;s personalization id. Note : this parameter is ignored if the userId parameter is specified.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public checkIsInSegmentByUserId(storeId: string, segmentId: string, userId?: number, personalizationId?: string, responseFormat?: string, options?: any) {
        return SegmentApiFp(this.configuration).checkIsInSegmentByUserId(storeId, segmentId, userId, personalizationId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets customer segment by segmentId.
     * @summary Get segment by the segment ID
     * @param {string} storeId The store identifier.
     * @param {string} segmentId The segment identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public getCustomerSegmentById(storeId: string, segmentId: string, responseFormat?: string, options?: any) {
        return SegmentApiFp(this.configuration).getCustomerSegmentById(storeId, segmentId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all customer segments by a specified storeId or get segment by userId or personalizationId or byName.
     * @summary Get segments by user or store ID
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {number} [qUserId] The user identifier. Required if the query is set to byUserId.
     * @param {string} [qPersonalizationId] The user\&#39;s personalization identifier. Required if the query is set to byPersonalizationId.
     * @param {string} [qName] The user\&#39;s name. Required if the query is set to byName.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public getCustomerSegments(storeId: string, q: string, qUserId?: number, qPersonalizationId?: string, qName?: string, pageNumber?: number, pageSize?: number, responseFormat?: string, options?: any) {
        return SegmentApiFp(this.configuration).getCustomerSegments(storeId, q, qUserId, qPersonalizationId, qName, pageNumber, pageSize, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
